import { titleImages, portfolioDetailsImages } from "./imageImport";

export const portfolioData = [
  {
    title: "Max Merge Solutions",
    id: "max-merge-solutions",
    // excerpt: "A bespoke document merging solution!",
    image: titleImages[0],
    details: {
      name: "Max Merge Solutions",
      excerpt:
        "A bespoke document merging solution for mortgage professionals, by mortgage professionals.",
      img: portfolioDetailsImages[0],
      lists: [
        {
          heading: "Obstacle",
          points: [
            "Full preliminary design and launch of a new brand",
            "Brand recognition and visibility",
            "Brand accessibility and engagement",
            "Social Media expansion and management",
          ],
        },
        {
          heading: "Resolution",
          points: [
            "Discovery + Branding",
            "Explainer + Tutorial Videos",
            "Digital Marketing",
            "Social Media Management",
            "Live Webinars",
          ],
        },
      ],
    },
  },
  {
    title: "heymate!",
    id: "heymate",
    // excerpt: "One app, one system. Limitless potential",
    image: titleImages[1],
    details: {
      name: "heymate!",
      excerpt:
        "heymate! means less divide, more conquer. Handling all your business operations in one platform, in minutes. ",
      img: portfolioDetailsImages[1],
      lists: [
        {
          heading: "Obstacle",
          points: [
            "Full preliminary design and launch of a new brand",
            "Kick-start audience and traction",
            "Make the platform accessible and engaging",
            "Optimize user flow",
          ],
        },
        {
          heading: "Resolution",
          points: [" Branding consultation", "Explainer video"],
        },
      ],
    },
  },
  {
    title: "Liberal Party of Canada",
    // excerpt: "Digital Marketing and Social Services",
    image: titleImages[2],
    id: "liberal-party-of-canada",
    details: {
      name: "Liberal Party of Canada",
      excerpt:
        "Amir Alavi is the Liberal Candidate for Prince George-Peace River-Northern Rockies in Canada’s 2021 election.",
      img: portfolioDetailsImages[2],
      lists: [
        {
          heading: "Obstacle",
          points: [
            "Develop public awareness",
            "Refine image for clear messaging",
            "Manage public perception",
          ],
        },
        {
          heading: "Resolution",
          points: [
            "Social Media Branding + Management",
            "Public Relations",
            "Digital Advertising",
          ],
        },
      ],
    },
  },
  {
    title: "Terre+Eau",
    id: "terre+eau",
    // excerpt: "Digital Marketing and Social Services",
    image: titleImages[3],
    details: {
      name: "Terre+Eau",
      excerpt:
        "Terre+Eau draws on elements from earth and sea to craft premium sanitizers. Concocted with the finest ingredients and essential oils from around the world.",
      img: portfolioDetailsImages[3],
      lists: [
        {
          heading: "Obstacle",
          points: [
            "Develop a clear brand voice",
            "Disseminate messaging",
            "Grow audience and conversion rates",
          ],
        },
        {
          heading: "Resolution",
          points: [
            "Product Photography",
            "Professional Editing",
            "Social Media",
            "Advertising Campaigns",
            "Influencer Marketing",
          ],
        },
      ],
    },
  },
  // {
  //   title: "Roomvu",
  //   // excerpt: "Digital Marketing and Social Services",
  //   image: titleImages[4],
  //   details: {
  //     name: "Roomvu",
  //     excerpt:
  //       "Roomvu is a professional real estate marketing and analytics platform curated by and for realtors.",
  //     img: portfolioDetailsImages[4],
  //     lists: [
  //       {
  //         heading: "Obstacle",
  //         points: ["Increase brand awareness", "Refine marketing approach"],
  //       },
  //       {
  //         heading: "Resolution",
  //         points: ["Live Webinars", "Social Media Consultation + Strategy"],
  //       },
  //     ],
  //   },
  // },
];

export const getSinglePortfolio = (id) => {
  const singlePortfolio = portfolioData.filter((p) => {
    return p.id === id;
  });

  return singlePortfolio[0] ? singlePortfolio[0].details : null;
};
