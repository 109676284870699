import React from "react";
import { Helmet } from "react-helmet";
import Spinner from "reactstrap/lib/Spinner";
import { NavBg } from "../Layout";
import styled from "styled-components";
const LoadingSpinner = ({ title }) => {
  return (
    <SpinnerWrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <NavBg />
      <Spinner
        color="primary"
        style={{
          width: "200px",
          height: "200px",
        }}
      />
    </SpinnerWrapper>
  );
};
const SpinnerWrapper = styled.div`
  min-height: calc(100vh - 343px);
  min-width: 100%;
  display: grid;
  place-items: center;
  .spinner-border {
    margin-top: -140px;
    color: var(--color-p-3) !important;
  }
`;
export default LoadingSpinner;
