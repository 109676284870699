import React from "react";
import styled from "styled-components";
import {
  AiFillLinkedin,
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";
import favicon from "../../Assets/favicon.png";
import { Link } from "react-router-dom";
const Modal = ({ text, close }) => {
  return (
    <ModalWrapper>
      <div className="modal-center">
        {text === "success" && (
          <div>
            <h3>
              <strong>Thank you for booking your free consultation.</strong>
            </h3>
            <h3>A team member will contact you shortly.</h3>
            <div className="social-container">
              <a
                href="https://www.linkedin.com/company/scm-inc/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <AiFillLinkedin />
              </a>
              <a
                href="https://www.facebook.com/Synic-Creative-Marketing-110241608055743/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <AiFillFacebook />
              </a>
              <a
                href="https://www.instagram.com/syniccm/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <AiFillInstagram />
              </a>
              <Link to="/">
                <img src={favicon} alt="scm site" />
              </Link>
            </div>
          </div>
        )}
        {text === "error" && (
          <div className="text-container">
            <h3>
              Oops. Something went wrong. Please try again later or contact us
              at{" "}
            </h3>
            <h3 className="text-center">
              <a href="mailto:admin@crdigital.ca">admin@crdigital.ca</a>
            </h3>
          </div>
        )}

        <button className="ok-btn" onClick={close}>
          ok
        </button>
      </div>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;

  .ok-btn {
    background-color: white;
    border: none;
    font-size: 14px;
    color: black;
    border-radius: 5px;
    padding: 8px 13px;
    @media screen and (min-width: 992px) {
      font-size: 16px;
      padding: 10px 15px;
    }
  }

  .text-container {
    display: grid;
    justify-content: center;
    a {
      color: var(--color-p-2);
      :hover {
        color: white;
      }
    }
  }

  .social-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    img {
      height: 19px;
      border-radius: 5px;
      margin-left: 3px;
      margin-right: 3px;
    }
    a {
      color: white;
      font-size: 24px;
      margin-left: 3px;
      margin-right: 3px;
    }
    @media screen and (min-width: 992px) {
      margin-top: 20px;
      a {
        font-size: 30px;
      }
      img {
        height: 24px;
      }
    }
  }

  .modal-center {
    width: 70%;
    height: 70%;
    position: relative;
    max-height: 260px;
    max-width: 260px;
    border: 10px solid var(--color-p-2);
    background-color: rgba(0, 0, 0, 0.75);
    display: grid;
    place-items: center;
    grid-template-rows: 1fr auto;
    padding: 20px;
    margin-right: -20px;
    @media screen and (min-width: 768px) {
      max-height: 300px;
      max-width: 300px;
    }
    @media screen and (min-width: 992px) {
      max-height: 350px;
      max-width: 350px;
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      max-height: 260px;
      max-width: 260px;
      border: 10px solid white;
      position: absolute;
      z-index: -1;
      top: -40px;
      left: -40px;
      @media screen and (min-width: 768px) {
        max-height: 300px;
        max-width: 300px;
      }
      @media screen and (min-width: 992px) {
        max-height: 350px;
        max-width: 350px;
      }
    }
  }

  h3 {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    overflow-wrap: break-word;
  }

  @media screen and (min-width: 992px) {
    h3 {
      font-size: 16px;
    }
  }
`;

export default Modal;
