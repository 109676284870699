import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

const Button02 = ({ children, click }) => {
  return (
    <ButtonWrapper>
      <Button
        variant="contained"
        className="btn"
        disableElevation
        onClick={click}
      >
        {children}
      </Button>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  .btn {
    /* min-width: 100%; */
    background: var(--color-p-2);
    color: var(--color-g-5);
    padding: 0 36px;
    position: relative;
    border: 2px solid var(--color-p-2);
    height: 3.25rem;
    font-size: 15px;
    transition: 0.4s;
    white-space: nowrap;
    :after {
      content: "»";
      position: absolute;
      opacity: 0;
      right: -20px;
      transition: 0.4s;
    }
    :hover {
      padding-right: 60px;
      padding-left: 12px;
      background: var(--color-p-2);
    }
    :hover:after {
      opacity: 1;
      right: 20px;
    }
  }
`;

export default Button02;
