import React from "react";
import styled from "styled-components";
import { Container } from "reactstrap";
import { NavBg } from "../../Components/Layout";
import { Helmet } from "react-helmet";

const PrivacyPolicyPage = () => {
  return (
    <PrivacyPolicyPageWrapper className="pt-15 pb-15">
      <NavBg />

      <Helmet>
        <title>Privacy Policies</title>
        <meta
          name="description"
          content="Learn how C&R Digital Consulting intends to use, collect and protect your information"
          data-react-helmet="true"
        />
      </Helmet>
      <Container>
        <header>
          <h1>Privacy Policy</h1>
          <p>
            C&R Digital Consulting.Inc is committed to safeguarding the
            personally identifiable information that you share with us online.
            Generally, you can browse our website, www.syniccreative.marketing
            (this “Site”), without sharing any personal information. However,
            should you wish to retain access to our proprietary and confidential
            resources (the “Resources”), or subscribe to our newsletter, or
            submit a form, you will be required to voluntarily submit personal
            information.
          </p>
          <p>
            We may also collect information on how you use this Site, such as
            resources browsed, searched, and downloaded; the frequency and
            duration of visits, and your source Internet Protocol (“IP”)
            address. Please note, this policy does not apply to information we
            may collect offline.
          </p>
          <p>
            Please read the following statement to understand how we collect,
            use, maintain, and disclose your personal information. Your use of
            this platform indicates consent to the collection, use, and
            disclosure of your information as described in this Privacy Policy.
          </p>
        </header>
        <section>
          <h1>What information do we collect?</h1>
          <article>
            <h2>Personally identifiable information</h2>
            <p>
              This is information that may be used to identify you as an
              individual, such as your email address, name, home or work
              address, or telephone number. We may collect this information in a
              number of voluntary ways on this Site, including, but not limited
              to, the online request a quote form, the newsletter form, as well
              as the comments form and email messages to any
              syniccreative.marketing email address. This information is used to
              respond to your requests and questions. We may also use this
              information to communicate with you about other relevant topics
              that may be of interest.
            </p>
            <h2>
              Non-personally identifiable information we collect by automated
              means
            </h2>
            <p>
              Non-personally Identifiable Information refers to information that
              does not by itself identify a specific individual. We may collect
              this information in a number of ways on this Site, including, but
              not limited to, how you use this Site, such as resources browsed,
              searched, and downloaded, and the frequency and duration of
              visits. As well, the Site may automatically collect and record
              other non-personal information, such as your IP address, browser
              type, unique device identifiers, the referring webpage, pages
              visited, and search terms.{" "}
            </p>
            <p>
              We use this information to make your experience with our Site
              informative and relevant. By collecting this information, we learn
              how to tailor our Site to our visitors and create meaningful
              website experiences for our online users.
            </p>
            <h2>Cookies</h2>
            <p>
              A cookie is a data file that certain websites write to your
              computer’s hard drive when you visit such sites. A cookie file can
              contain information, such as a user identification code, that the
              site uses to track the pages you have visited, but the only
              personal information a cookie can contain is information you
              supply yourself.{" "}
            </p>
            <p>
              A cookie can’t read data off your hard disk or read cookie files
              created by other sites. We may collect information from your web
              sessions by using cookies, to determine, for example, user traffic
              patterns, and the effectiveness of our navigational structure.
            </p>
            <p>
              Most website browsers automatically accept cookies, but you can
              usually change your browser settings to display a warning before
              accepting a cookie, or to refuse all cookies. You don’t need to
              have cookies turned on to use/navigate through many parts of this
              Site. However, you may have to accept the receipt of cookies to
              access some Resources on this Site.
            </p>
            <p>
              Please note that if you choose not to accept cookies, or to delete
              the use of cookies, you may affect your website experience and
              render yourself unable to use all of the available Site features.
            </p>
            <h2>Google Analytics</h2>
            <p>
              We may use third-party web analytics services to analyze how users
              use the Site. This can include Google Analytics, which uses
              technologies such as cookies to gather user information. The
              information collected by the cookies about your use of the Site
              can include your IP address, the URL visited, or page view date
              and time, and will be transmitted and stored by Google on servers
              in the United States.
            </p>
            <p>
              Google may compile reports on the Site activity for other website
              operators who are providing services related to website activity
              and internet usage. Google may also transfer this information to
              third parties when required by law, or when such third parties
              process information on behalf of Google.{" "}
            </p>
            <p>
              For more information about Google's privacy policy in respect of
              Google Analytics, please refer to{" "}
              <a
                href="http://www.google.com/analytics/learn/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                google analytics privacy policies.{" "}
              </a>
              You may opt out of Google Analytics by visiting
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl+en=GB"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                google analytics opt-out browser add-on.{" "}
              </a>
            </p>
            <h2>Children</h2>
            <p>
              We respect the privacy of children. This Site is not intended for
              children and we do not knowingly solicit personal data from or
              communicate with children under the age of 13. As well, the Site
              is not intended for incapacitated persons and we do not knowingly
              solicit personal data from or communicate with incapacitated
              persons. Further, this Site is created for use by adults. By using
              the Site, you are consenting that you are at least 18 years old or
              using the Site with the permission of a parent or guardian. If you
              become aware that any child under your care, or person unable to
              prove valid consent, has provided us with information, please
              contact us by email at{" "}
              <a href="mailto:admin@crdigital.ca">admin@crdigital.ca.</a>{" "}
              You can also write to us at the address listed at the end of this
              policy.
            </p>
          </article>
          <article>
            <h1>How we use your information</h1>
            <p>
              If you choose to provide C&R Digital Consulting.Inc with your
              personal information, where appropriate, Synic Creative
              Marketing.Inc or subsidiary, representative, sales, or affiliated
              sales channel representative, may use this information to contact
              you, in order to further our relationship with you.
            </p>
            <ul>
              <li>
                We use the personal information collected during the use of this
                Site to personalize web content where applicable.
              </li>
              <li>
                We may also use your information to send you direct marketing
                information or contact you for market research.
              </li>
              <li>
                We may contact you when changes are made to our secure content
                site.
              </li>
              <li>
                We may periodically send emails for sales, marketing, and
                administrative purposes.
              </li>
              <li>
                We will periodically send you an email when you are subscribed
                to our customer notification list for the latest updates of our
                corporate and product news, and/or any other subscription
                list(s).
              </li>
              <li>
                We may disclose information about our users when we believe, in
                good faith, that the disclosure is required by law.
              </li>
              <li>
                We may process, evaluate, and respond to your requests or
                applications directly.
              </li>
              <li>
                We comply with all applicable legal requirements and industry
                standards within our policies.
              </li>
            </ul>
            <h2>Links to other websites</h2>
            <p>
              The Site may provide links to other websites for your convenience
              and information. These websites may operate independently from us
              and users should note that linked sites may have their own privacy
              notices or policies. We strongly encourage our users to review any
              linked website policies in order to understand how they may
              collect, use, and disclose your personal information.{" "}
            </p>
            <p>
              This Privacy Policy applies solely to information collected by
              this Site. We do not take responsibility for the content of linked
              websites, any use of their sites, or the privacy practices or
              policies of these sites.{" "}
            </p>
            <h2>Maintaining your information</h2>
            <p>
              We take information security issues seriously. Consequently, any
              personally identifiable information is stored and protected on
              servers with adequate security measures. We will cease all
              communication and use of personally identifiable information upon
              your request. Your privileges for access to the Resources on this
              Site may then be suspended.
            </p>
            <p>
              We only keep personal information as long as it is necessary to
              fulfil the purposes for which it was collected. We also keep any
              information that is required by law.{" "}
            </p>
            <h2>Security</h2>
            <p>
              We take the security of information seriously. We are committed to
              using reasonable measured to protect any personal information we
              may collect. However, due to the oddity and open nature of the
              internet, we cannot fully guarantee that the information stored on
              this site, or direct communications between you and us, is
              absolutely secure.
            </p>
            <p>
              Although we take steps to protect your data, by using the Site you
              consent that the transmission of data is at your own risk. By
              using the Site, you agree to assume all risks in connection with
              data sent, collected, or stored by us.
            </p>
            <h2>Access, updates, and your security choices</h2>
            <p>
              At any time, you can ask us to halt any marketing communications
              by email by clicking on the Unsubscribe link within the marketing
              email.
            </p>
            <p>
              If you have provided personal information on the Site, you may
              request, at any time, that it be updated, modified, or deleted.
            </p>
            <p>
              You may complete either of these actions by sending a request to
              the following email:{" "}
              <a href="mailto:admin@crdigital.ca">admin@crdigital.ca.</a>{" "}
            </p>
            <p>
              You also have the ability to adjust your advertising preferences
              on social media websites by adjusting your Settings on the
              individual platform. For example, you can adjust the type of
              Instagram or Facebook ads you receive through your Facebook
              Settings or the type of Google ads you receive through your Google
              Settings.
            </p>
          </article>
          <article>
            <h1>Consent</h1>
            <p>
              By using this Site, you consent to the processing of your personal
              information as outlined in this Privacy Policy.
            </p>
          </article>
          <article>
            <h1>Contact us</h1>
            <p>
              If you have questions, concerns, requests, or amendments to make
              in regards to this Privacy Policy, please email us at:{" "}
              <a href="mailto:admin@crdigital.ca">admin@crdigital.ca.</a>{" "}
            </p>
            <p>
              Or, write to us at C&R Digital Consulting.Inc, 409 Granville St
              #702, Vancouver, BC V6C 1A3
            </p>
            <p>
              You may also contact us at the above addresses at any time to
              learn more or request a quote.
            </p>
          </article>
          <article>
            <h1>Changes to this statement</h1>
            <p>
              If there are changes or additions to the terms of this Privacy
              Policy, we will post those changes here prior to implementing the
              change, as such, C&R Digital Consulting.Inc encourages you to
              periodically review this Privacy Policy so you are informed on how
              your information is being protected and used.
            </p>
            <p>
              If there are material changes to this Privacy Policy, Synic
              Creative Marketing.Inc. will notify the users of such changes
              using the personally identifiable information collected on this
              Site.
            </p>
            <p>
              <b>Privacy Notice Effective Date: September 1, 2021</b>
            </p>
          </article>
        </section>
      </Container>
    </PrivacyPolicyPageWrapper>
  );
};

const PrivacyPolicyPageWrapper = styled.div`
  h1,
  h2 {
    color: #1e1d28;
    font-size: 40px;
    margin-bottom: 1rem;
    margin-top: 2rem;
    font-weight: 700;
  }
  h2 {
    font-size: 30px;
    font-weight: 400;
    margin-top: 0;
  }
  p,
  li {
    font-size: 16px;
    color: #454545;
  }

  ul {
    list-style: outside;
    padding-left: 50px;
  }
`;
export default PrivacyPolicyPage;
