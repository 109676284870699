import React from "react";
import hero from "../../Assets/landing/hero.png";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import useWindowDimensions from "../../Utility/windowDimensions";
import LandingForm from "./LandingForm";
const LandingHero = () => {
  const { width } = useWindowDimensions();
  return (
    <LandingHeroWrapper>
      <Container>
        <Row>
          <Col
            xs="12"
            lg="6"
            className="heading d-flex flex-column justify-content-start"
          >
            {width >= 992 && (
              <h1 className="large-h1">
                Leave
                <br />
                Your Online
                <br />
                Advertising to us
              </h1>
            )}
            {width < 992 && (
              <h1 className="text-center small-h1">
                Leave Your
                <br />
                Online advertising
                <br />
                to us
              </h1>
            )}
            <LandingForm hero="true" />
          </Col>
          <Col lg="6">
            <div className="square"></div>
          </Col>
        </Row>
      </Container>
    </LandingHeroWrapper>
  );
};

const LandingHeroWrapper = styled.div`
  width: 100%;
  min-height: calc(100% - 91px);
  /* background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url(${hero}) center/cover no-repeat;
  background-attachment: fixed; */
  padding-top: 100px;
  padding-bottom: 100px;

  @media screen and (max-width: 768px) {
    display: grid;
    place-items: center;
  }

  h1 {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.7;
  }

  .small-h1 {
    font-size: 30px;
  }

  .large-h1 {
    font-size: 44px;
    line-height: 1.4;
  }

  @media screen and (min-width: 768px) {
    .small-h1 {
      font-size: 38px;
    }
  }

  @media screen and (min-width: 1200px) {
    .large-h1 {
      font-size: 48px;
    }
  }

  .heading {
    display: flex;
    justify-content: center;
  }

  .col {
    padding: 0;
  }

  .square {
    display: none;
    border: 14px solid var(--color-p-2);
    height: calc(80vh - 200px);
    min-height: 400px;
    position: relative;
    margin-left: 20px;
    &:after {
      content: "";
      position: absolute;
      border: 8px solid white;
      width: 105%;
      height: calc(80vh - 200px);
      min-height: 400px;
      top: -40px;
      left: -40px;
    }
  }

  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 105px);
    padding-top: 100px;
    padding-bottom: 100px;
    .square {
      display: block;
    }
  }

  @media screen and (min-width: 1080px) {
    .square {
      margin-left: 0px;
      width: 100%;
    }
  }
  @media screen and (min-width: 1400px) {
    .square {
      margin-left: 80px;
      width: 100%;
    }
  }
`;

export default LandingHero;
