import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap/lib";
import styled from "styled-components";
import Button02 from "../../Components/Shared/Button02";
import NavBg from "../../Components/Layout/NavBg";
import validator from "validator";
import { IoCloseOutline } from "react-icons/io5";
import {
  projects,
  services,
  timeline,
  budgetLabel,
} from "../../Data/quoteFormData";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Slider,
} from "@material-ui/core";
import useWindowDimensions from "../../Utility/windowDimensions";
import { withStyles } from "@material-ui/core/styles";
import CustomTextField from "../../Components/Shared/CustomTextField";
import { Helmet } from "react-helmet";

import { formatPhone } from "../../Utility/formatPhone";

const CustomCheckbox = withStyles({
  root: {
    color: "var(--color-g-2)",
    "&$checked": {
      color: "var(--color-p-3)",
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "var(--color-g-3)",
        zIndex: -1,
      },
    },
  },

  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const QuotePage = () => {
  const { width } = useWindowDimensions();

  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState({
    projects: Object.fromEntries(projects.map((project) => [project, false])),
    services: Object.fromEntries(services.map((service) => [service, false])),
    timelineValue: timeline.large[0].value,
    timeline: timeline.large[0].label,
    budget: budgetLabel[0].value,
  });

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [messageVal, setMessageVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);

  const resetAll = () => {
    setData({
      projects: Object.fromEntries(projects.map((project) => [project, false])),
      services: Object.fromEntries(services.map((service) => [service, false])),
      timeline: timeline.large[0].label,
      timelineValue: timeline.large[0].value,
      budget: budgetLabel[0].value,
    });
    setEmail("");
    setName("");
    setCompany("");
    setPhone("");
    setMessage("");
    setTimeout(() => {
      setModalOpen(false);
    }, 700);
  };

  const handlePhone = ({ target: { value } }) => {
    setPhone((prev) => formatPhone(value, prev));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let tempError = validateForm();
    setLoading(true);
    setClasses(["animated", "state-1"]);

    if (tempError) {
      setTimeout(() => {
        setClasses([]);
        setLoading(false);
      }, 1000);
    } else {
      setClasses(["animated", "state-1", "state-2"]);
      setTimeout(() => {
        resetAll();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 500);
      setLoading(false);
    }
  };

  const validateForm = () => {
    let tempError = false;
    if (!email) {
      setEmailVal("Email is required");
      tempError = true;
    } else if (!validator.isEmail(email)) {
      setEmailVal("Please check if your email is correct");
      tempError = true;
    } else {
      setEmailVal("");
    }
    if (!message) {
      setMessageVal("Please include a message for us!");
      tempError = true;
    } else {
      setMessageVal("");
    }
    return tempError;
  };

  const handleProjectsCheck = (project) => {
    setData({
      ...data,
      projects: {
        ...data.projects,
        [project]: !data.projects[project],
      },
    });
  };

  const handleServicesCheck = (service) => {
    setData({
      ...data,
      services: {
        ...data.services,
        [service]: !data.services[service],
      },
    });
  };

  const handleTimelineChange = (e, val) => {
    e.preventDefault();
    setData({
      ...data,
      timeline: timeline.large[val].label,
      timelineValue: val,
    });
  };

  const handleBudgetChange = (e, val) => {
    e.preventDefault();
    setData({ ...data, budget: val });
  };

  return (
    <QuotePageWrapper>
      <Helmet>
        <title>Request a Quote</title>
        <meta
          name="description"
          content="Tell us about your project and request a quote. A member of C&R Digital Consulting team will contact you shortly after. We meet your budget and timeline"
          data-react-helmet="true"
        />
      </Helmet>
      <NavBg />
      <form noValidate>
        <Container className="max-width pt-20 pb-15">
          <div className="header mb-4">
            <h2 className="mb-1 text-center">Request a Quote</h2>
            <p className="text-center">Tell Us About Your Project</p>
          </div>
          <Row className="mb-4">
            <Col lg="3" className="mb-1 mt-lg-1">
              <h4>Projects</h4>
            </Col>
            <Col lg="9 d-sm-flex flex-wrap">
              {projects.map((project, index) => {
                return (
                  <FormGroup
                    className="col-sm-6 form-check checkgroup"
                    key={index}
                  >
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          className="form-check-input"
                          onChange={() => handleProjectsCheck(project)}
                          checked={data.projects[project]}
                        ></CustomCheckbox>
                      }
                      className="form-check-label"
                      label={project}
                    />
                  </FormGroup>
                );
              })}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="3" className="mb-1">
              <h4>Timeline</h4>
            </Col>
            <Col lg="9">
              <Slider
                defaultValue={0}
                step={1}
                min={0}
                value={data.timelineValue}
                onChange={(e, val) => handleTimelineChange(e, val)}
                max={timeline.small.length - 1}
                marks={width > 992 ? timeline.large : timeline.small}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="3" className="mb-1 d-md-flex">
              <h4>Budget</h4>
            </Col>
            <Col lg="9">
              <Slider
                defaultValue={100}
                value={data.budget}
                step={100}
                min={100}
                valueLabelDisplay={`${
                  data.budget > budgetLabel[0].value ? "on" : "auto"
                }`}
                onChange={(e, val) => handleBudgetChange(e, val)}
                max={budgetLabel[budgetLabel.length - 1].value}
                marks={budgetLabel}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="3" className="mb-1 mt-lg-1">
              <h4>Services</h4>
            </Col>
            <Col lg="9 d-flex flex-wrap">
              {services.map((service, index) => {
                return (
                  <FormGroup
                    className="col-6 form-check checkgroup"
                    key={index}
                  >
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          className="form-check-input"
                          onChange={() => handleServicesCheck(service)}
                          checked={data.services[service]}
                        ></CustomCheckbox>
                      }
                      className="form-check-label"
                      label={service}
                    />
                  </FormGroup>
                );
              })}
            </Col>
          </Row>
          <Row className="d-flex justify-content-center justify-content-md-start">
            <Col xs="0" md="3"></Col>
            <Col xs="12" md="4" lg="2">
              <Button02 click={() => setModalOpen(true)}>Continue</Button02>
            </Col>
          </Row>
        </Container>
        <div
          className={`screen ${modalOpen ? "screen-open" : ""}`}
          onClick={() => setModalOpen(false)}
        ></div>
        <div className={`custom-modal ${modalOpen ? "modal-open" : ""}`}>
          <Container>
            <Row className="mb-1 mb-md-2 mt-1">
              <Col className="d-flex justify-content-start">
                <div className="modal-text">
                  <p>Fill in your...</p>
                </div>
              </Col>
              <Col className="d-flex justify-content-end">
                <button
                  className="close-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalOpen(false);
                  }}
                >
                  <IoCloseOutline />
                </button>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg="6" className="mb-2">
                <CustomTextField
                  type="text"
                  label="Name"
                  fullWidth
                  variant="outlined"
                  value={name}
                  InputLabelProps={{
                    style: {
                      top: "-2px",
                    },
                  }}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col lg="6" className="mb-2">
                <CustomTextField
                  type="text"
                  value={company}
                  label="Company"
                  onChange={(e) => setCompany(e.target.value)}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      top: "-2px",
                    },
                  }}
                />
              </Col>

              <Col lg="6" className="mb-2">
                <CustomTextField
                  type="email"
                  value={email}
                  label="Email"
                  error={emailVal === "" ? false : true}
                  className={emailVal ? "is-invalid" : ""}
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  InputLabelProps={{
                    style: {
                      top: "-2px",
                    },
                  }}
                />
                <div className="invalid-feedback">{emailVal}</div>
              </Col>
              <Col lg="6" className="mb-2">
                <CustomTextField
                  value={phone}
                  label="Phone #"
                  fullWidth
                  variant="outlined"
                  onChange={handlePhone}
                  InputLabelProps={{
                    style: {
                      top: "-2px",
                    },
                  }}
                />
              </Col>
              <Col>
                <textarea
                  className={`form-control ${messageVal ? "is-invalid" : ""}`}
                  id="exampleFormControlTextarea1"
                  rows="9"
                  placeholder="Message *"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <div className="invalid-feedback">{messageVal}</div>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="d-flex justify-content-end">
                <button
                  disabled={loading}
                  className={`submit-button state-0 ${classes.join(" ")}`}
                  onClick={handleSubmit}
                >
                  <span className="pre-state-msg">Submit</span>
                  <span className="current-state-msg hide">Sending...</span>
                  <span className="done-state-msg hide">Done</span>
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      </form>
    </QuotePageWrapper>
  );
};

const QuotePageWrapper = styled.div`
  position: relative;
  color: rgba(0, 0, 0, 0.7);

  button {
    outline: none;
  }

  .header {
    h2 {
      color: black;
      font-size: 36px;
      font-weight: 600;
    }
    p {
      color: var(--color-g-4);
      font-size: 16px;
    }
  }

  h4 {
    padding-left: 20px;
    font-size: 18px;
  }

  @media screen and (min-width: 768px) {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  @media screen and (min-width: 992px) {
    h2 {
      font-size: 48px;
    }
    p {
      font-size: 20px;
    }
  }
  @media screen and (min-width: 1200px) {
    h2 {
      font-size: 50px;
    }
    p {
      font-size: 22px;
    }
  }

  .checkgroup {
    margin-bottom: 10px;
    white-space: nowrap;
  }

  .MuiSlider-root {
    color: var(--color-p-4);
    width: 90%;
    margin-left: 25px;

    .MuiSlider-rail {
      height: 3px;
    }
    .MuiSlider-thumb {
      margin-top: -4px;
    }
    .MuiSlider-valueLabel {
      left: -23px;
      top: -20px;
    }
    .MuiSlider-valueLabel > span {
      height: 25px;
      width: 55px;
      transform: rotate(0);
      border-radius: 7px;
    }
    .MuiSlider-valueLabel > span > span {
      font-size: 14px;
      color: var(--color-g-4);
      transform: rotate(0);
      :before {
        content: "$";
      }
    }
  }

  .custom-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    min-width: 100%;
    height: 80vh;
    overflow: scroll;
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--color-p-2);
    }
    @media screen and (min-width: 768px) {
      height: 75vh;
    }
    @media screen and (min-width: 992px) {
      height: 70vh;
    }
    @media screen and (min-width: 1200px) {
      height: 65vh;
    }

    transform: translateY(100%);
    transition: 0.3s;
    z-index: 2;
    .close-button {
      background: none;
      border: none;
      font-size: 30px;
    }
    .modal-text {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      p {
        padding: 0;
        margin: 0;
      }
    }

    input,
    textarea {
      resize: none;
      background: #f8f8f8;
      :focus {
        background: white;
      }
    }

    textarea {
      padding-top: 15px;
      padding-bottom: 15px;
      box-sizing: border-box;
      :focus {
        box-shadow: none;
        border: 1px solid var(--color-p-4);
      }
    }
  }

  .modal-open {
    transform: translateY(0%);
  }

  .screen {
    position: fixed;
    top: 0;
    display: none;
    background: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    min-width: 100vw;
    z-index: 1;
  }

  .screen-open {
    display: block;
  }
`;

export default QuotePage;
