import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const StylingContext = React.createContext();

export const StylingProvider = ({ children }) => {
  const location = useLocation();
  const [isLandingPage, setIsLandingPage] = useState(true);

  useEffect(() => {
    if (location.pathname === "/online-advertising") {
      setIsLandingPage(true);
    } else {
      setIsLandingPage(false);
    }
  }, [location]);

  const value = {
    isLandingPage,
  };

  return (
    <StylingContext.Provider value={value}>{children}</StylingContext.Provider>
  );
};

export const useStylingContext = () => {
  return useContext(StylingContext);
};
