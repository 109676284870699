import React, { useState, useRef } from "react";
import { Router } from "react-router-dom";
import Routes from "./Components/Layout/Routes";
import { Navbar, Sidebar, Footer, ScrollButton } from "./Components/Layout";
import ScrollToTop from "./Components/Layout/ScrollToTop";
import { Helmet } from "react-helmet";
import history from "./Components/Layout/history";
import styled from "styled-components";

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <AppWrapper>
      <Router history={history}>
        <Helmet></Helmet>
        <ScrollToTop />
        <Navbar setSidebarOpen={setSidebarOpen} />
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Routes />
        <ScrollButton />
        <Footer />
      </Router>
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1220px;
    }
  }
`;

export default App;
