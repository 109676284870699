import React, { useState } from "react";
import styled, { css } from "styled-components";
import { CustomTextField2 } from "../Shared/CustomTextField";
import { formatPhone } from "../../Utility/formatPhone";
import validator from "validator";
import axios from "axios";
import Spinner from "reactstrap/lib/Spinner";
import Modal from "./Modal";
const LandingForm = ({ hero }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [nameVal, setNameVal] = useState("");
  const [phoneVal, setPhoneVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalText, setModalText] = useState("");

  const handlePhone = ({ target: { value } }) => {
    setPhone((prev) => formatPhone(value, prev));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let err = validateForm();
    if (!err) {
      axios
        .post(process.env.REACT_APP_SYNIC_BE + "/landing", {
          email,
          name,
          phone,
        })
        .then((resp) => {
          setModal(true);
          if (resp.data.statusCode === 202) {
            //do succesfull
            setEmail("");
            setName("");
            setPhone("");
            setModalText("success");
          } else {
            // do failed shit
            setModalText("error");
          }
          setLoading(false);
        })
        .catch(() => {
          //do failed shit
          setModal(true);
          setModalText("error");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let tempErr = false;
    if (!email) {
      setEmailVal("Required");
      tempErr = true;
    } else if (!validator.isEmail(email)) {
      setEmailVal("Not valid");
      tempErr = true;
    } else {
      setEmailVal("");
    }
    if (!name) {
      setNameVal("Required");
      tempErr = true;
    } else {
      setNameVal("");
    }
    if (!phone) {
      setPhoneVal("Required");
      tempErr = true;
    } else if (phone.length !== 14) {
      setPhoneVal("Not valid");
      tempErr = true;
    } else {
      setPhoneVal("");
    }

    return tempErr;
  };

  return (
    <LandingFormWrapper className="mt-1" hero={hero}>
      {modal && (
        <Modal
          text={modalText}
          close={() => {
            setModal(false);
          }}
        />
      )}
      {loading && (
        <div className="screen">
          <Spinner
            color="secondary"
            style={{ width: "300px", height: "300px" }}
          />
        </div>
      )}
      <h2 className="mb-2">
        Book your free <br /> consultation now
      </h2>
      <form>
        <div className="g-group mb-3">
          <div className="field">
            <CustomTextField2
              type="name"
              value={name}
              label="Name"
              error={nameVal === "" ? false : true}
              className={`mr-1 ${nameVal ? "is-invalid" : ""}`}
              fullWidth
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              required
              InputLabelProps={{
                style: {
                  top: "-2px",
                  color: "#ebebeb",
                },
              }}
            />
            <div className="invalid-feedback">{nameVal}</div>
          </div>
          <div className="field">
            <CustomTextField2
              value={phone}
              label="Phone #"
              error={phoneVal === "" ? false : true}
              className={`${phoneVal ? "is-invalid" : ""} ml-1`}
              fullWidth
              variant="outlined"
              onChange={handlePhone}
              InputLabelProps={{
                style: {
                  top: "-2px",
                  color: "#ebebeb",
                },
              }}
            />
            <div className="ml-1 invalid-feedback">{phoneVal}</div>
          </div>
        </div>
        <div className="field">
          <CustomTextField2
            type="email"
            value={email}
            label="Email"
            error={emailVal === "" ? false : true}
            className={`mb-1 ${emailVal ? "is-invalid" : ""}`}
            fullWidth
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            required
            InputLabelProps={{
              style: {
                top: "-2px",
                color: "#ebebeb",
              },
            }}
          />
          <div className="invalid-feedback">{emailVal}</div>
        </div>
        <div className="btn-container">
          <button className="mt-2" onClick={handleSubmit}>
            Book Now
          </button>
        </div>
      </form>
    </LandingFormWrapper>
  );
};

const LandingFormWrapper = styled.div`
  .screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }

  h2 {
    color: var(--color-p-2);
    font-size: 28px;
    line-height: 1.8;
    text-align: center;
  }

  button {
    color: var(--color-p-2);
    font-size: 20px;
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;
    font-weight: 600;
    box-shadow: inset 0 0 0 0 var(--color-p-2);
    -webkit-transition: ease-out 0.35s;
    -moz-transition: ease-out 0.35s;
    transition: ease-out 0.35s;
    letter-spacing: 1px;
    border: 1px solid var(--color-p-2);
    padding: 6px 18px;
    border-radius: 5px;
    :hover {
      box-shadow: inset 0 100px 0 0 var(--color-p-2);
      color: rgba(0, 0, 0, 0.8);
    }
  }

  @media screen and (min-width: 768px) {
    h2 {
      font-size: 34px;
    }
  }

  @media screen and (min-width: 992px) {
    h2 {
      font-size: 36px;
    }
    form {
      padding-right: 40px;
    }
    ${({ hero }) =>
      hero &&
      css`
        h2 {
          text-align: left;
        }
        .btn-container {
          justify-content: start;
        }
      `}
  }

  .field {
    position: relative;
  }

  .invalid-feedback {
    min-height: 19px;
    height: 19px;
    color: #ff7373;
    position: absolute;
    top: 47px;
    left: 5px;
    text-align: start;
  }

  .g-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-right: 10px;
  }

  .spinner-border {
    color: var(--color-p-3) !important;
  }
`;

export default LandingForm;
