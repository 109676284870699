import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { sidebarLinks } from "../../Data/linksData";
import { scrollToElement } from "../../Utility";
import history from "./history";
import { useStylingContext } from "../../Contexts/StylingContext";

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <SidebarWrapper sidebarOpen={sidebarOpen}>
      <div className="sidebar-header">
        <div className="sidebar-close">
          <button onClick={closeSidebar}>
            <IoCloseOutline />
          </button>
        </div>
      </div>
      <ul className="sidebar-body">
        {sidebarLinks.map((link, index) => {
          return (
            <li key={index}>
              <Link
                to={link.url}
                onClick={() => {
                  closeSidebar();
                }}
              >
                {link.name}
              </Link>
              {/* check if sublinks exists */}
              {link.sublinks && (
                <div className="sublinks">
                  {link.sublinks.map((sublink, index) => {
                    return sublink.target_outside ? (
                      <a
                        key={index}
                        href={sublink.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          closeSidebar();
                        }}
                      >
                        {sublink.name}
                      </a>
                    ) : (
                      <Link
                        to={sublink.url}
                        key={index}
                        onClick={() => {
                          closeSidebar();
                        }}
                      >
                        {sublink.name}
                      </Link>
                    );
                  })}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled.aside`
  min-width: 100vw;
  min-height: 100vh;
  z-index: 999;
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(100%);
  background: linear-gradient(-151deg, var(--color-p-1) 40%, #fff 100%);
  transition: 0.25s;

  .sidebar-header {
    display: flex;
    padding: 20px 30px;
    justify-content: right;
    align-items: center;
  }

  .logo {
    flex-grow: 1;
  }

  .sidebar-close {
    display: flex;
    height: 30px;
    align-items: center;
    font-size: 35px;
    button {
      color: var(--color-g-3);
      outline: none;
      border: none;
      background: none;
      display: flex;
      align-items: center;
    }
  }

  .sidebar-body {
    margin: 0;
    padding: 0 30px;
    min-width: 100vw;
    min-height: 100vh;
    background: none;
    a {
      transition: 0.2s;
    }
    li:not(:first-child) {
      border-top: 1px solid var(--color-g-2);
    }

    li > a {
      letter-spacing: 0em;
      display: block;
      line-height: 1.5;
      text-transform: none;
      padding: 10px 0;
      font-size: 22px;
      color: rgba(255, 255, 255, 0.7);
      color: var(--color-g-3);
      font-weight: 700;
      line-height: 1.5;
      :hover,
      :focus,
      :active {
        /* padding-left: 7px; */
        color: var(--color-g-4);
      }
    }

    .sublinks {
      margin-bottom: 15px;
      display: grid;
      grid-template-rows: 1fr;
      a,
      button {
        font-size: 18px;
        color: #fff;
        color: var(--color-g-3);
        border: none;
        background: none;
        text-align: left;
        padding: 5px 15px;
        transition: 0.25s;
        :hover,
        :focus,
        :active {
          padding-left: 25px;
          color: #686868;
          background-color: rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  ${({ sidebarOpen }) =>
    sidebarOpen &&
    css`
      transform: translateX(0%);
    `}

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

export default Sidebar;
