import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsList } from "react-icons/bs";
import { GoTriangleDown } from "react-icons/go";
import { Collapse } from "reactstrap";
import { navbarLinks } from "../../Data/linksData";
import { NavbarWrapper } from "./styled/NavbarStyled";

const Navbar = ({ setSidebarOpen }) => {
  const [navScroll, setNavScroll] = useState(false);
  const [dropDownIndex, setDropDownIndex] = useState(0);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const toggleNav = () => {
    if (window.scrollY > 2) {
      setNavScroll(true);
    } else {
      setNavScroll(false);
    }
  };

  window.addEventListener("scroll", toggleNav);

  const handleDropDown = (index) => {
    if (index === dropDownIndex && dropDownOpen) {
      setDropDownOpen(false);
    } else {
      setDropDownIndex(index);
      setDropDownOpen(true);
    }
  };

  return (
    <NavbarWrapper navScroll={navScroll} dropDownOpen={dropDownOpen}>
      <nav className={`container ${navScroll ? "nav-container" : ""}`}>
        <div className="row">
          <div className="col-lg-12">
            <div className="header-wrap d-flex justify-content-between">
              <div className="branding">
                <Link
                  to="/"
                  className={`${navScroll ? "logo-invert" : "logo"}`}
                >
                  <h1 className="logo-name">C&amp;R Digital Consulting</h1>
                </Link>
              </div>
              <div className="button-container d-flex">
                <div className="links-container">
                  <ul>
                    {navbarLinks.map((link, index) => {
                      return (
                        <li key={index}>
                          {link.sublinks ? (
                            <button
                              onClick={() => handleDropDown(index)}
                              className={`${
                                index === dropDownIndex && dropDownOpen
                                  ? "active-link"
                                  : ""
                              }`}
                            >
                              {link.name}{" "}
                              <span>
                                <GoTriangleDown />
                              </span>
                            </button>
                          ) : (
                            <Link
                              to={link.url}
                              onClick={() => {
                                setDropDownOpen(false);
                              }}
                            >
                              {link.name}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* <div className="button quote-button">
                  <Link
                    to="/quote"
                    onClick={() => {
                      setDropDownOpen(false);
                    }}
                  >
                    Get a Quote
                  </Link>
                </div> */}
                <div className="sidebar-btn">
                  <button
                    onClick={() => {
                      setSidebarOpen(true);
                    }}
                  >
                    <BsList />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Collapse className="dropDown" isOpen={dropDownOpen}>
        {navbarLinks[dropDownIndex].sublinks && (
          <div className="container">
            <div className="row d-flex">
              <div className="container py-3 col">
                <div className="intro">
                  <h4 className="fz-16 font-weight-bold">
                    {navbarLinks[dropDownIndex].name}
                  </h4>
                  <p className="fz-16 text  -desc-2 mb-1">
                    {navbarLinks[dropDownIndex].excerpt}
                  </p>
                  <Link
                    to={navbarLinks[dropDownIndex].viewLink.url}
                    onClick={() => {
                      setDropDownOpen(false);
                    }}
                  >
                    {navbarLinks[dropDownIndex].viewLink.text}
                  </Link>
                </div>
              </div>
              <div className="container py-3 col">
                <div className="sublinks">
                  {navbarLinks[dropDownIndex].sublinks.map((sublink, index) => {
                    if (sublink.target_outside)
                      return (
                        <a
                          href={sublink.url}
                          key={index}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            setDropDownOpen(false);
                          }}
                        >
                          {sublink.name}
                        </a>
                      );
                    return (
                      <Link
                        to={sublink.url}
                        key={index}
                        onClick={() => {
                          setDropDownOpen(false);
                        }}
                      >
                        {sublink.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </Collapse>
    </NavbarWrapper>
  );
};

export default Navbar;
