import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap/lib";
import aboutbg from "../../Assets/about/aboutbg.jpg";
import processImg from "../../Assets/about/process.png";
import { Helmet } from "react-helmet";
const AboutPage = () => {
  return (
    <AboutPageWrapper background={aboutbg}>
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <section className="header pt-20 pb-15">
        <Container>
          <Row>
            <Col md="10" lg="8" xl="6">
              <h1 className="mb-3">About Us</h1>
              <p className="mb-3">
                Digital branding for the 21st-century entrepreneur.
              </p>
              <p>
                We not only provide you with the answers to specific problems,
                like a paid advertisement, web development but also provide you
                with a tactical follow through with a team approach.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section bg-1">
        <Container>
          <Row className="limit-width">
            <Col>
              <h1 className="title mb-2 mb-lg-3">Who We Are</h1>
              <p>
                Our team is made up of career professionals with diverse
                experience, driven by a passion for equality of access. We
                thrive on the variety that digital marketing brings. Eking out
                the particular magic of your brand, putting your purpose front
                and center.
              </p>
              <p>
                Our approach is holistic, knowing it’s not just about what you
                present; it's how you present. Everything with intentionality,
                so that from a simple post to a campaign event, all can be
                distilled to a clear message. Your message!
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section">
        <Container>
          <Row className="mb-3">
            <Col>
              <h1 className="title  mb-2 mb-lg-3">Core Values</h1>
              <p>
                The core value of C&R Digital Consulting is non-negotiable
                during projects, with deliverables driven by integrity and
                intentionality. From needing social media management to grant
                writing, when you work with C&R Digital Consulting, this is
                what you can expect.
              </p>
            </Col>
          </Row>
          <Row className="core-section">
            <Col xs="12" lg="4" className="mb-2">
              <h2>Quality</h2>
              <p>
                At SCM we aim to deliver a strategic plan in accordance with its
                client’s needs. Whether it’s SEO services, social media, email,
                B2B marketing or paid advertisements etc. We have your brand’s
                back in exploring, strategizing, executing and optimizing.{" "}
              </p>
            </Col>
            <Col xs="12" lg="4" className="mb-2">
              <h2>Efficiency</h2>
              <p>
                A marketing approach that is efficient today might not work two
                years from now. Here at SCM we have marketing plans from direct
                to web marketing based on the market research of current and
                future trends.{" "}
              </p>
            </Col>
            <Col xs="12" lg="4" className="mb-2">
              <h2>Collaboration</h2>
              <p>
                The brand story of SCM is quite simple as a few cool lads got
                together with{" "}
                <a
                  href="https://synicsw.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Synic Software
                </a>{" "}
                to help you market your brand. We built our brand with trust and
                have an empathetic environment where we could share ideas.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section bg-2">
        <Container>
          <Row>
            <Col lg="7" xl="8" className="mb-3 mb-lg-0">
              <h1 className="title mb-2 mb-lg-3">Process</h1>
              <p>
                The life cycle of a project occurs in several stages to ensure
                your satisfaction with form, usability, and efficacy. From
                creative design to innovative custom marketing solutions, we're
                at the forefront of manifesting tomorrow's genius, today.
              </p>
            </Col>
            <Col lg="5" xl="4" className="img-wrapper">
              <img src={processImg} alt="processes" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section">
        <Container>
          <Row className="mb-2 mb-md-3 mb-lg-4 mb-xl-5">
            <Col>
              <h1 className="title  mb-2 mb-lg-3 text-center">Team</h1>
              <p className="text-center team-text mb-2">
                Our team is career professionals who value growth and community.
                Hiring individuals who are innovators in their field mean
                collaborative enrichment for exceptional results.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </AboutPageWrapper>
  );
};

const AboutPageWrapper = styled.div`
  .section {
    padding: 115px 0px;
  }

  .limit-width {
    max-width: 55rem;
  }

  .title {
    color: black;
  }

  .team-text {
    max-width: 800px;
    margin: 0 auto;
  }

  h1 {
    font-size: 36px;
    font-weight: 600;
    color: #2d2d2d;
  }

  p {
    font-size: 16px;
    color: #3e4243;
  }

  h2 {
    font-size: 20px;
    color: #454545;
  }

  @media screen and (min-width: 992px) {
    h1 {
      font-size: 48px;
    }
    p {
      font-size: 18px;
    }
    .core-section {
      p {
        font-size: 16px;
      }
    }
    h2 {
      font-size: 28px;
    }
  }

  .header {
    min-width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url(${(props) => props.background}) center/cover no-repeat;
    h1,
    p {
      color: white;
    }
  }
  .bg-1 {
    background-color: #f1f1f1;
  }
  .bg-2 {
    background-color: #080808;
    p,
    h1 {
      color: white;
    }
  }
`;

export default AboutPage;
