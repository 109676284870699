import React from "react";
import styled from "styled-components";
import Hero from "../../Components/Home/Hero";
import { Helmet } from "react-helmet";
import home01 from "../../Assets/home/home01.jpg";
import { Container, Row, Col } from "reactstrap";

const HomePage = () => {
  return (
    <div>
      <Helmet></Helmet>
      <Hero />
      <Wrapper>
        {/* place holder stuff */}
        <div className="placeholder01 py-3">
          <img src={home01} alt="homepic01" />
          <div className="home-content">
            <h1>10 years of experience</h1>
            <p>
              Over 10 years of experience in the information technology field
              and our experienced counselors are ready to help you!
            </p>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  background-color: #464646;
  img {
    width: 100%;
  }
  .placeholder01 {
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .home-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 100px;
    text-align: center;
    background-color: white;
    h1,
    p {
      color: #1b1b1b;
      padding-bottom: 2rem;
    }
    h1 {
      font-weight: 700;
      font-size: 48px;
    }
    p {
      font-size: 18px;
    }
  }
`;

export default HomePage;
