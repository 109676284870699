import React from "react";
import styled from "styled-components";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Button02 from "../Shared/Button02";
import { Link } from "react-router-dom";
import AppearWrapper from "../Shared/AppearWrapper";
const InfoVertical = ({ data, bg, reverseOrder, alwaysAppear }) => {
  const { title, text, cards, button, image } = data;

  return (
    <InfoVerticalWrapper bg={bg} className="py-10">
      <AppearWrapper alwaysAppear={alwaysAppear}>
        <Container>
          <Row>
            <Col
              lg="6"
              className={`mb-3 mb-lg-0 ${reverseOrder ? "order-md-2" : ""}`}
            >
              <img src={image} alt={title} className="vertical-img" />
            </Col>
            <Col
              lg="6"
              className={`mb-3 mb-lg-0 ${reverseOrder ? "order-md-1" : ""}`}
            >
              <Row>
                <Col lg="12">
                  {title.map((item, index) => {
                    return (
                      <h1 key={index} className="text-left mb-1">
                        {item}
                        <br />
                      </h1>
                    );
                  })}
                  <div className="divider"></div>
                </Col>
              </Row>
              <Col lg="12" xl="11">
                <Row className="mt-2">{text && <p>{text}</p>}</Row>
                {cards.map((card, index) => {
                  return (
                    <Row key={index} className="mt-3 mb-3">
                      <Col lg="3" className="mb-2 mb-lg-0 pt-lg-2 text-center">
                        <div className="icon p-3 ">
                          <img src={card.image} alt={card.name} />
                        </div>
                      </Col>
                      <Col lg="8" className="mt-1 mb-1">
                        <h4>{card.name}</h4>
                        {card.text && <p>{card.text}</p>}
                        {card.list && (
                          <ul className="d-flex flex-md-row flex-column flex-lg-column">
                            {card.list.map((li, index) => {
                              return (
                                <li key={index} className="mr-md-3">
                                  {li}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </Col>
                    </Row>
                  );
                })}
                <div className="d-flex justify-content-center">
                  <Link to={button.url}>
                    <Button02>{button.text}</Button02>
                  </Link>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </AppearWrapper>
    </InfoVerticalWrapper>
  );
};

const InfoVerticalWrapper = styled.section`
  background-color: ${(props) => (props.bg ? props.bg : "white")};

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .vertical-img {
    border-radius: 25px;
    /* border: 1px solid var(--color-p-2); */
    /* box-shadow: 0 5px 10px 8px rgba(0, 0, 0, 0.1); */
  }

  .divider {
    margin: 0;
  }

  li {
    white-space: nowrap;
  }

  h4 {
    font-weight: 500;
  }

  p {
    line-height: 1.7;
    color: #3e4243;
    font-size: 16px;
  }

  ul {
    list-style: inside;
    color: black;
    font-size: 16px;
    line-height: 2;
  }
`;

export default InfoVertical;
