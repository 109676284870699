import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "reactstrap";
import portfoliobg from "../../Assets/portfolio/portfoliobg.jpg";
import { portfolioData } from "../../Data/portfolioData";
import PortfolioCard from "../../Components/Portfolio/PortfolioCard";
import Button02 from "../../Components/Shared/Button02";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const PortfolioPage = () => {
  return (
    <PortfolioPageWrapper className="pb-10" background={portfoliobg}>
      <Helmet>
        <title>Portfolio</title>
        <meta
          name="description"
          content="C&R Digital Consulting's diverse clientele have seen tangible ROI and elevated engagement as a result of our collaboration"
          data-react-helmet="true"
        />
      </Helmet>
      <div className="header pt-18 pb-14 mb-8 mb-md-10 mb-lg-12 mb-xl-14">
        <Container>
          <Col md="11" lg="9">
            <h1>Portfolio</h1>
            <p>
              Electrifying your brand, and theirs. Our diverse clientele have
              seen tangible ROI and elevated engagement as a result of our
              collaboration. Here's the latest.
            </p>
          </Col>
        </Container>
      </div>
      <Container>
        {portfolioData.map((portfolio, index) => {
          return (
            <PortfolioCard
              portfolio={portfolio}
              key={index}
              left={index % 2 === 0 ? "true" : ""}
              index={index}
            />
          );
        })}
      </Container>
      <Row className="d-flex justify-content-center mt-3">
        <Link to="quote">
          <Button02>get started now</Button02>
        </Link>
      </Row>
      <Row className="d-flex justify-content-center mt-4">
        <p className="link-outside">
          Check out Our Team's{" "}
          <a
            href="https://synicsw.com/portfolio"
            target="_blank"
            rel="noopener noreferrer"
          >
            Portfolio »
          </a>
        </p>
      </Row>
    </PortfolioPageWrapper>
  );
};

const PortfolioPageWrapper = styled.div`
  background-color: var(--color-bg);

  .link-outside {
    font-size: 16px;
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
  }

  .header {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url(${(props) => props.background}) center/cover no-repeat;

    h1 {
      line-height: 1.285;
      font-size: 40px;
      margin-bottom: 20px;
      color: white;
      font-weight: 600;
    }
    p {
      font-size: 17px;
      color: white;
    }
    @media screen and (min-width: 996px) {
      h1 {
        font-size: 50px;
      }
      p {
        font-size: 18px;
      }
    }
    @media screen and (min-width: 1200px) {
      h1 {
        font-size: 58px;
        margin-bottom: 30px;
      }
    }
  }
`;

export default PortfolioPage;
