import React from "react";
import styled from "styled-components";
import Logo from "../../Assets/logo-color.svg";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
const Footer2 = () => {
  return (
    <Footer2Wrapper>
      <Container className="d-flex justify-content-between">
        <div className="footer-left">
          <div>
            <img src={Logo} alt="C&R Digital Consulting" />
          </div>

          <div className="mt-1 copyright">
            <p>
              Copyright &copy; {new Date().getFullYear()} All Rights reserved.
            </p>
            <p>C&R Digital Consulting INC.</p>
          </div>
        </div>
        <div className="links-container">
          <ul>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
      </Container>
    </Footer2Wrapper>
  );
};

const Footer2Wrapper = styled.footer`
  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1300px !important;
    }
  }

  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;

  .footer-left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  p {
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 14px;
  }

  img {
    height: 45px;
  }

  .links-container {
    font-weight: 600;
  }
  a {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    :hover {
      color: var(--color-p-5);
    }
  }
  ul {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px;
  }

  @media screen and (min-width: 992px) {
    padding-top: 25px;
    padding-bottom: 25px;
    a,
    p {
      font-size: 16px;
    }

    .copyright {
      display: flex;
    }
  }
`;

export default Footer2;
