import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  ErrorPage,
  HomePage,
  PortfolioPage,
  ServicesPage,
  QuotePage,
  ContactPage,
  PrivacyPolicyPage,
  AboutPage,
  SinglePortfolioPage,
  CareersPage,
  SingleCareerPage,
  LandingPage,
} from "../../Pages/public";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      {/* <Route exact path="/online-advertising">
        <LandingPage />
      </Route> */}
      {/* <Route exact path="/portfolio">
        <PortfolioPage />
      </Route>
      <Route
        exact
        path="/portfolio/:id"
        children={<SinglePortfolioPage />}
      ></Route> */}
      <Route exact path="/about-us">
        <AboutPage />
      </Route>
      <Route exact path="/privacy-policy">
        <PrivacyPolicyPage />
      </Route>
      <Route exact path="/contact">
        <ContactPage />
      </Route>
      <Route exact path="/careers">
        <CareersPage />
      </Route>
      <Route exact path="/careers/:id" children={<SingleCareerPage />} />
      <Route exact path="/quote">
        <QuotePage />
      </Route>
      <Route exact path="/services">
        <ServicesPage />
      </Route>
      {/* <Route exact path="/services/:id" children={<ServicesPage />} /> */}
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );
};

export default Routes;
