import React, { useState } from "react";
import styled, { css } from "styled-components";
import ReactPlayer from "react-player/youtube";
import { Container, Row, Col } from "reactstrap/lib";
import Button01 from "../Shared/Button01";
import { Link } from "react-router-dom";
import heroImg from "../../Assets/herobg.jpeg";

const Hero = () => {
  const [videoReady, setVideoReady] = useState(false);

  return (
    <HeroWrapper
      className="container-fluid pt-15 pt-md-20 pb-5"
      videoReady={videoReady}
    >
      <div className="video-container">
        <ReactPlayer
          url="https://www.youtube.com/embed/WbfjRoQVZSw&t=10s&modestbranding=1&autohide=1&showinfo=0"
          loop={true}
          muted={true}
          controls={false}
          playing={true}
          onPlay={() => setVideoReady(true)}
          playbackRate={0.9}
        ></ReactPlayer>
      </div>

      <Container>
        <Row>
          <Col className="col d-flex justify-content-center flex-column">
            <div className="d-flex justify-content-center flex-column text-center align">
              <AnimationWrapper
                className="block-reveal"
                theme={{ bg: "white", d: "0.1s" }}
              >
                Experienced,
              </AnimationWrapper>
              <AnimationWrapper
                className="block-reveal"
                theme={{ bg: "var(--color-p-2)", d: "0.3s" }}
              >
                Personalized,
              </AnimationWrapper>
              <AnimationWrapper
                className="block-reveal"
                theme={{ bg: "white", d: "0.5s" }}
              >
                IT Consulting
              </AnimationWrapper>
              {/* <AnimationWrapper
                className="block-reveal"
                theme={{ bg: "var(--color-p-2)", d: "0.7s" }}
              >
                Digital Life
              </AnimationWrapper> */}
            </div>
          </Col>
        </Row>
        <Row className="d-flex flex-column flex-sm-row justify-content-center mt-3 align-items-center fade-in">
          <Link to="/contact">
            <Button01>Book Now</Button01>
          </Link>
          <Link to="/about-us" className="mt-3 mt-sm-0">
            <Button01 outlined="true">About Us</Button01>
          </Link>
        </Row>
      </Container>
    </HeroWrapper>
  );
};

const AnimationWrapper = styled.h1`
  --td: 1s;
  --d: ${(props) => props.theme.d};
  --t: calc(var(--td) + var(--d));
  --animation-bg: ${(props) => props.theme.bg};

  color: transparent;
  padding: 4px;
  position: relative;
  width: 100%;
  overflow: hidden;
  animation: revealBlock 0s var(--t) forwards;

  @media screen and (min-width: 768px) {
    width: 80%;
  }

  @media screen and (min-width: 992px) {
    width: 70%;
  }

  @media screen and (min-width: 1200px) {
    /* width: 50%; */
  }

  h1 {
    white-space: no-wrap;
  }

  :after {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--animation-bg);
    z-index: 2;
    animation: revealingIn var(--td) var(--d) forwards,
      revealingOut var(--td) var(--t) forwards;
  }

  /* animations */
  @keyframes revealBlock {
    100% {
      color: white;
    }
  }

  @keyframes revealingIn {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes revealingOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(102%);
    }
  }
`;

const HeroWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: grid;
  align-items: center;

  .fade-in {
    opacity: 0;
    animation: fadeInFromNone 1.4s forwards;
    animation-delay: 1s;
  }

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  h1 {
    text-transform: uppercase;
    align-self: center;
    font-weight: 900;
    font-size: 40px;
    letter-spacing: 4px;
  }

  @media screen and (min-width: 768px) {
    h1 {
      font-size: 50px;
    }
  }

  @media screen and (min-width: 992px) {
    h1 {
      font-size: 65px;
    }
  }

  @media screen and (min-width: 1200px) {
    h1 {
      font-size: 80px;
    }
  }

  .video-container {
    /* background-color: black; */
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      url(${heroImg}) center/cover no-repeat;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: -99;
    :after {
      content: "";
      min-width: 100vw;
      min-height: calc(100vh + 300px);
      top: 0;
      position: fixed;
      left: 0;
      background-color: rgba(0, 0, 0, 0.75);
    }
  }

  iframe {
    position: fixed;
    top: -60px;
    left: 0;
    width: 100%;
    height: calc(120px + (100vw * 9) / 16);
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
  }

  ${({ videoReady }) =>
    videoReady &&
    css`
      iframe {
        animation: fadeInFromNone 1.4s forwards;
        opacity: 1;
      }
    `}

  @media (min-aspect-ratio: 16/9) {
    iframe {
      height: 300%;
      top: -100%;
    }
  }

  @media (max-aspect-ratio: 16/9) {
    .video-container {
      width: 100%;
    }
    iframe {
      width: calc((100vh * 16) / 9);
      height: calc(100vh + 120px);
    }
  }
  @media all and (max-width: 600px) {
    .vid-info {
      width: 50%;
      padding: 0.5rem;
    }
    .vid-info h1 {
      margin-bottom: 0.2rem;
    }
  }
  @media all and (max-width: 500px) {
    .vid-info .acronym {
      display: none;
    }
  }
`;

export default Hero;
