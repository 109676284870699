import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "reactstrap";
import CustomTextField from "../Shared/CustomTextField";
import validator from "validator";

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [messageVal, setMessageVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    let tempError = validateForm();
    setLoading(true);
    setClasses(["animated", "state-1"]);

    if (tempError) {
      setTimeout(() => {
        setClasses([]);
        setLoading(false);
      }, 1000);
    } else {
      //submit
      setClasses(["animated", "state-1", "state-2"]);
      setTimeout(() => {
        resetAll();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 500);
      setLoading(false);
    }
  };

  const resetAll = () => {
    setEmail("");
    setName("");
    setMessage("");
  };

  const validateForm = () => {
    let tempError = false;
    if (!email) {
      setEmailVal("Email is required");
      tempError = true;
    } else if (!validator.isEmail(email)) {
      setEmailVal("Please check if your email is correct");
      tempError = true;
    } else {
      setEmailVal("");
    }
    if (!message) {
      setMessageVal("Please include a message for us!");
      tempError = true;
    } else {
      setMessageVal("");
    }
    return tempError;
  };

  return (
    <ContactFormWrapper>
      <form noValidate>
        <Container>
          <Row className="mb-3">
            <Col xs="12">
              <h1>Contact Us</h1>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6" className="mb-3">
              <CustomTextField
                type="Name"
                value={name}
                label="Name"
                fullWidth
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  style: {
                    top: "-2px",
                  },
                }}
              />
            </Col>
            <Col xs="12" lg="6" className="mb-3">
              <CustomTextField
                type="email"
                value={email}
                label="Email"
                error={emailVal === "" ? false : true}
                className={emailVal ? "is-invalid" : ""}
                fullWidth
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                required
                InputLabelProps={{
                  style: {
                    top: "-2px",
                  },
                }}
              />
              <div className="invalid-feedback">{emailVal}</div>
            </Col>

            <Col className="mb-3">
              <textarea
                className={`form-control ${messageVal ? "is-invalid" : ""}`}
                id="exampleFormControlTextarea1"
                rows="9"
                placeholder="Message *"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="invalid-feedback">{messageVal}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <button
                disabled={loading}
                className={`submit-button state-0 ${classes.join(" ")}`}
                onClick={handleSubmit}
              >
                <span className="pre-state-msg">Submit</span>
                <span className="current-state-msg hide">Sending...</span>
                <span className="done-state-msg hide">Done</span>
              </button>
            </Col>
          </Row>
        </Container>
      </form>
    </ContactFormWrapper>
  );
};

const ContactFormWrapper = styled.div`
  h1 {
    color: #343a40;
    font-size: 45px;
  }

  input,
  textarea {
    resize: none;
    background: #f8f8f8;
    :focus {
      background: white;
      box-shadow: none;
    }
  }

  textarea {
    padding-top: 15px;
    padding-bottom: 15px;
    box-sizing: border-box;
    background-image: none !important;
    :focus {
      box-shadow: none !important;
      border: 1px solid var(--color-p-4) !important;
    }
  }
`;

export default ContactForm;
