import collaboration from "../Assets/icons/collaboration.svg";
import chatBubble from "../Assets/icons/chat-bubble.svg";
import dashboard from "../Assets/icons/dashboard.svg";
import zoom from "../Assets/icons/zoom.svg";
import book from "../Assets/icons/book.svg";
import layout from "../Assets/icons/layout.svg";
import globe from "../Assets/icons/globe.svg";
import menu from "../Assets/icons/menu.svg";
import file from "../Assets/icons/file.svg";
import microphone from "../Assets/icons/microphone.svg";
import play from "../Assets/icons/play.svg";
import monitor from "../Assets/icons/monitor.svg";
import shoppingBag from "../Assets/icons/shopping-bag.svg";

export const infoData01 = {
  title: ["Digital Transformation"],
  cards: [
    {
      name: "Consulting",
      text: "Consulting at C&R is building a better working world by realizing business transformation through the power of people, technology and innovation.",
      image: collaboration,
    },
    {
      name: "Digitalize your Business",
      text: "We help you convert your manual pen & paper processes into digitalized ones",
      image: chatBubble,
    },
    {
      name: "Focus on your Business",
      text: "We will introduce software systems that are tailored for your business so you don't have to worry about the mundane chores anymore",
      image: dashboard,
    },
  ],
  button: {
    url: "/contact",
    text: "Talk to Us",
  },
};

export const infoData02 = {
  title: ["Online Marketing"],
  cards: [
    {
      name: "Name your platform",
      text: "From Google to YouTube, we'll help you create and implement a campaign on any platform you fancy. With a brainy development team, you'll get optimum functionality. Anytime, anyplace.",
      image: book,
    },
    {
      name: "Curate your content",
      text: "As a full-service digital media agency, we create advertisements from concept to implementation, or help you distribute your centrepiece swag. Your vision. Your brand. Front and centre.",
      image: layout,
    },
    {
      name: "In-house Expertise",
      text: "A bigger logo of your company isn’t enough for gaining attention. Our in-house experts not only geek out on keywords to get your brand marketed but aim for conversion rate optimisation and search engine optimisation.",
      image: zoom,
    },
  ],
  button: {
    url: "/contact",
    text: "Hold Inquiry",
  },
};

export const infoData03 = {
  title: ["Grant Writing"],
  cards: [
    {
      name: "Researching Opportunities",
      text: "Need wage subsidies? Looking to fund a new venture? Seeking support for specific communities? We're here to help. Collating eligible opportunities, in alignment with your goals.",
      image: globe,
    },
    {
      name: "Formal Proposals",
      text: "Whether it's from scratch to submission, or if you're just looking for a structure overhaul, we've got your back. We'll highlight your core purpose and prioritize your evidence for the strongest case possible.",
      image: menu,
    },
    {
      name: "Pitch Deck",
      text: "Curated for your goals, targets and tone. Whether it's a horror film or an SaaS solution, our team will package your project to perfection. Speech prompts are also available for oral presentation.",
      image: zoom,
    },
  ],
  button: {
    url: "/contact",
    text: "Get our attention",
  },
};

export const infoData04 = {
  title: ["Cyber Security"],
  cards: [
    {
      name: "Managed Security Services",
      text: "Alerting, Correlation Investigation & Escalation powered by HG Analytics",
      image: globe,
    },
    {
      name: "Advisory Services",
      text: "Governance, Risk & Compliance, Privacy & PCI Compliance Support",
      image: menu,
    },
    {
      name: "Security Assessments & Testing",
      text: "Vulnerability Management, Penetration Testing, Red Team Operations",
      image: zoom,
    },
  ],
  button: {
    url: "/contact",
    text: "Get our attention",
  },
};

export const infoVertical01 = {
  title: ["Website &", "Development"],
  cards: [
    {
      name: "UX/UI Design & Testing",
      text: "The immediacy of the 21st century means that if an interface, i.e. website, isn't intuitive to use or easy to look at, it'll lose rep. This is especially true for millennials and Gen Z. We develop in a responsive way, with live-time feedback for fast-tracked evolution. Grounded in research (which we take care of) and tested by our team. All you'll need to do is drop.",
      image: monitor,
    },
    {
      name: "E-Commerce",
      list: [
        "Consultant/Groupie",
        "Copywriter",
        "Amazon",
        "Alibaba",
        "Shopify",
      ],
      image: shoppingBag,
    },
  ],
  button: {
    text: "Let's initiate",
    url: "/contact",
  },
  image:
    "https://res.cloudinary.com/dp94c6f82/image/upload/w_668,h_625,c_crop,f_auto,q_auto/v1623995747/synic-new/marketing/website-landing_yybemy.jpg",
};

export const infoVertical02 = {
  title: ["Content Creation"],
  text: "Our studio team is made up of multi-talented consultants with real-world experience. They will define your content in the context of its industry. Whether you need ongoing support for world domination, or literally a one-and-done blog post, we got you.",
  cards: [
    {
      name: "Copy-writing & SEO",
      list: ["Creative script writing", "SEO consulting"],
      image: file,
    },
    {
      name: "Visual Arts",
      list: ["Visual content", "Custom logo design", "Brand strategy"],
      image: layout,
    },
    {
      name: "Media Production",
      list: [
        "Digital storyboards",
        "Custom logo design",
        "On-site photography & videography",
      ],
      image: play,
    },
    {
      name: "Podcast Creation",
      list: ["Audio", "Visual"],
      image: microphone,
    },
  ],
  button: {
    text: "Let's Create",
    url: "/contact",
  },
  image:
    "https://res.cloudinary.com/dp94c6f82/image/upload/w_619,h_836,c_crop,f_auto,q_auto/v1623996220/synic-new/marketing/content_nvuwrd.jpg",
};
