import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap/lib";
import NavBg from "../../Components/Layout/NavBg";
import { getSinglePortfolio } from "../../Data/portfolioData";
import useWindowDimensions from "../../Utility/windowDimensions";
import Button02 from "../../Components/Shared/Button02";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../Components/Shared/LoadingSpinner";
import ErrorSection from "../../Components/Shared/ErrorSection";
const SinglePortfolioPage = () => {
  const { id } = useParams();
  const [portfolio, setPortfolio] = useState(null);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setLoading(() => true);
    const singlePortfolio = getSinglePortfolio(id);
    setPortfolio(singlePortfolio);
    setLoading(() => false);
  }, [id, loading]);

  if (loading) {
    return <LoadingSpinner title="Portfolio | Loading" />;
  }

  if (!portfolio) {
    return (
      <ErrorSection
        title="portfolio | Not Found"
        category="project"
        link="/portfolio"
        btn="Back to Portfolio"
      />
    );
  }

  const { name, excerpt, img, lists } = portfolio;

  return (
    <SinglePortfolioPageWrapper className="pt-20 pb-10">
      <Helmet>
        <title>Portfolio | {name}</title>
      </Helmet>
      <NavBg />
      <Container>
        {width > 1200 ? (
          <Row>
            <Col lg="6">
              <h1 className="mb-2">{name}</h1>
              <p className="mb-4">{excerpt}</p>
              {lists.map((list, index) => {
                const { heading, points } = list;
                return (
                  <Row key={index} className="mb-2">
                    <Col xs="12">
                      <h2>{heading}</h2>
                    </Col>
                    <Col xs="12">
                      <ul>
                        {points.map((point, index) => {
                          return <li key={index}>{point}</li>;
                        })}
                      </ul>
                    </Col>
                  </Row>
                );
              })}
            </Col>
            <Col className="text-center" lg="6">
              <img src={img} alt="" />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs="12">
              <h1 className="mb-1">{name}</h1>
              <p>{excerpt}</p>
            </Col>
            <Col className="text-center mb-3" xs="12">
              <img src={img} alt="" />
            </Col>
            <Col xs="12">
              {lists.map((list, index) => {
                const { heading, points } = list;
                return (
                  <Row key={index} className="mb-2">
                    <Col xs="12">
                      <h2>{heading}</h2>
                    </Col>
                    <Col xs="12">
                      <ul>
                        {points.map((point, index) => {
                          return <li key={index}>{point}</li>;
                        })}
                      </ul>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        )}
        <Row className="d-flex justify-content-center justify-content-lg-start mt-3 ">
          <Link to="/quote">
            <Button02>Get Started Now</Button02>
          </Link>
        </Row>
      </Container>
    </SinglePortfolioPageWrapper>
  );
};

const SinglePortfolioPageWrapper = styled.div`
  h1,
  h2 {
    color: #1e1d28;
    font-size: 44px;
  }

  h2 {
    font-size: 22px;
  }

  p {
    color: #454545;
    font-size: 18px;
  }

  img {
    height: 300px;
    object-fit: cover;
    @media screen and (min-width: 576px) {
      height: 400px;
    }
    @media screen and (min-width: 768px) {
      height: 450px;
    }
    @media screen and (min-width: 992px) {
      height: 500px;
    }
    @media screen and (min-width: 1200px) {
      height: 550px;
    }
  }

  ul {
    margin-left: 40px;
    list-style-type: disc;
  }
  li {
    color: #454545;
    font-size: 18px;
  }
`;

export default SinglePortfolioPage;
