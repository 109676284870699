import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./CSS/custom.css";
import "./CSS/index.css";
import withClearCache from "./ClearCache";

const ClearCacheApp = withClearCache(App);

ReactDOM.render(
  <React.Fragment>
    <ClearCacheApp />
  </React.Fragment>,
  document.getElementById("root")
);

registerServiceWorker();
