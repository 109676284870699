import React from "react";
import styled from "styled-components";
import { Row, Col, Container } from "reactstrap";
import { NavBg } from "../../Components/Layout";
import ContactInfo from "../../Components/Contact/ContactInfo";
import ContactForm from "../../Components/Contact/ContactForm";
// import Map from "../../Components/Contact/Map";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <ContactPageWrapper className="pt-20">
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Contact us to see how we can work together. Phone: (236) 591-6882 Email: admin@crdigital.ca"
          data-react-helmet="true"
        />
      </Helmet>
      <NavBg />
      <Container className="mb-8">
        <Row>
          <Col xs="12" lg="3">
            <ContactInfo />
          </Col>
          <Col>
            <ContactForm />
          </Col>
        </Row>
      </Container>

      {/* <Map /> */}
    </ContactPageWrapper>
  );
};

const ContactPageWrapper = styled.div``;

export default ContactPage;
