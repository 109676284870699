import React, { useEffect } from "react";
import styled from "styled-components";
import InfoVertical from "../../Components/Home/InfoVertical";
import { useParams } from "react-router-dom";
import InfoHorizontal from "../../Components/Home/InfoHorizontal";
import { scrollToElement } from "../../Utility";
import Container from "reactstrap/lib/Container";
import { Helmet } from "react-helmet";

import {
  infoData01,
  infoData02,
  infoData03,
  infoData04,
  infoVertical01,
  infoVertical02,
} from "../../Data/homePageData";

const paramName = {
  "social-media-management": 0,
  "website-and-development": 1,
  "content-creation": 2,
  marketing: 3,
  "grant-writing": 4,
};

const ServicesPage = () => {
  const { id } = useParams();

  return (
    <ServicesPageWrapper className="services">
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="C&R Digital Consulting not only provides you with services like PPC and web development but also provides you with full social media management"
          data-react-helmet="true"
        />
      </Helmet>
      <div className="header pt-20 pb-15">
        <Container>
          <h1>Services</h1>
        </Container>
      </div>
      <InfoHorizontal
        data={infoData01}
        bg="var(--color-bg)"
        id="h01"
        alwaysAppear={"true"}
      />

      <InfoVertical
        data={infoVertical01}
        bg="var(--color-g-1)"
        id="h02"
        alwaysAppear={"true"}
      />

      <InfoVertical
        id="h03"
        data={infoVertical02}
        bg="var(--color-bg)"
        reverseOrder="true"
        alwaysAppear={"true"}
      />
      <InfoHorizontal
        data={infoData02}
        bg="var(--color-g-1)"
        id="h04"
        alwaysAppear={"true"}
      />
      <InfoHorizontal
        data={infoData03}
        bg="var(--color-bg)"
        id="h05"
        alwaysAppear={"true"}
      />
      <InfoHorizontal
        data={infoData04}
        bg="var(--color-bg)"
        id="h05"
        alwaysAppear={"true"}
      />
    </ServicesPageWrapper>
  );
};

const ServicesPageWrapper = styled.div`
  .header {
    min-width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url("https://res.cloudinary.com/dp94c6f82/image/upload/h_480,c_scale,f_auto,q_auto/v1623981348/synic-new/service2.jpg")
        center/cover no-repeat;
    h1 {
      color: rgba(255, 255, 255, 1);
      font-size: 38px;
    }
  }

  @media screen and (min-width: 768px) {
    .header {
      h1 {
        font-size: 48px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .header {
      h1 {
        font-size: 58px;
      }
    }
  }
`;

export default ServicesPage;
