import React from "react";
import styled from "styled-components";
import LandingHero from "../../Components/Landing/LandingHero";
import { landingData } from "../../Data/landingData";
import Info from "../../Components/Landing/Info";
import { Container } from "reactstrap";
import LandingForm from "../../Components/Landing/LandingForm";
import hero from "../../Assets/landing/hero.png";
const LandingPage = () => {
  return (
    <LandingPageWrapper>
      <LandingHero />
      {landingData.map((val, index) => {
        return <Info key={index} data={val} />;
      })}
      <div className="form-container">
        <Container className="section-center">
          <h1>
            Find out which type suits <br />
            your business best
          </h1>
          <LandingForm />
        </Container>
      </div>
    </LandingPageWrapper>
  );
};

const LandingPageWrapper = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url(${hero}) center/cover no-repeat;
  background-attachment: fixed;

  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1220px;
    }
  }

  .form-container {
    margin-top: -10px;
    padding: 150px 0;
    padding-bottom: 200px;
    /* background: #272727; */
    text-align: center !important;
  }

  .section-center {
    display: grid;
    place-items: center;
  }

  h1 {
    color: var(--color-p-2);
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 30px;

    @media screen and (min-width: 992px) {
      font-size: 34px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 40px;
    }
  }
`;

export default LandingPage;
