export const projects = [
  "Social Media Management",
  "Web Design & Development",
  "Content Creation",
  "Digital Marketing Services",
  "Grant Writing",
];

export const services = [
  "Consultation",
  "User Research",
  "Design",
  "Development",
  "Testing",
  "Launch",
  "Ongoing Support",
];

export const timeline = {
  small: [
    {
      value: 0,
      label: "not sure",
    },
    {
      value: 1,
      label: "1mo",
    },
    {
      value: 2,
      label: "6mo",
    },
    {
      value: 3,
      label: "1yr",
    },
    {
      value: 4,
      label: "1.5yr",
    },
    {
      value: 5,
      label: "2yr",
    },
    {
      value: 6,
      label: "∞",
    },
  ],
  large: [
    {
      value: 0,
      label: "not sure",
    },
    {
      value: 1,
      label: "1 month",
    },
    {
      value: 2,
      label: "6 month",
    },
    {
      value: 3,
      label: "1 year",
    },
    {
      value: 4,
      label: "1.5 year",
    },
    {
      value: 5,
      label: "2 years",
    },
    {
      value: 6,
      label: "∞",
    },
  ],
};

export const budget = [
  "$100 - $500",
  "$500 - $1000",
  "$1000 - $2000",
  "$2000 - $3000",
  "$3000 - $4000",
  "$4000 - $5000",
  "$5000+",
];

export const budgetLabel = [
  {
    value: 100,
    label: "$100",
  },
  {
    value: 2500,
    label: "$2500",
  },
  {
    value: 5000,
    label: "$5000",
  },
  {
    value: 7500,
    label: "$7500",
  },
  {
    value: 10000,
    label: "$10000+",
  },
];
