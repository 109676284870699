import React from "react";
import { Row, Col } from "reactstrap";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
const PortfolioCard = ({ portfolio, left, index }) => {
  const { image, title, excerpt, id } = portfolio;

  return (
    <PortfolioCardWrapper
      className={`mb-5 mb-md-6 mb-lg-8 ${left ? "" : "justify-content-end"}`}
      left={left}
    >
      <Col xs="12" md="7" className={`mb-2 ${left ? "" : "order-md-2"}`}>
        <Link to={`/portfolio/${id}`} className="img-link">
          <div className="img-wrapper">
            <img src={image} alt={title} />
            <div className="screen" />
            <div className="overlay">
              {left ? (
                <div className="overlay-heading">
                  <span>
                    <FiArrowLeft />
                  </span>
                  Learn More
                </div>
              ) : (
                <div className="overlay-heading">
                  Learn More
                  <span>
                    <FiArrowRight />
                  </span>
                </div>
              )}
              <div className={`black-bar`}></div>
            </div>
          </div>
        </Link>
      </Col>
      <Col
        md="5"
        className={`d-flex align-items-end ${left ? "" : "order-md-1"}`}
      >
        <div className={`text-content ${left ? "" : "text-md-right"}`}>
          <Link to={`/portfolio/${id}`}>
            <h2>{title}</h2>
          </Link>
          <p>{excerpt}</p>
        </div>
      </Col>
    </PortfolioCardWrapper>
  );
};

const PortfolioCardWrapper = styled(Row)`
  .img-wrapper {
    box-shadow: 0 2px 20px rgb(0 0 0 / 6%);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }

  .text-content {
    width: 100%;
  }

  .overlay {
    width: 50%;
    height: 100%;
    position: absolute;
    background-color: white;
    transition: 0.5s;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    transform: ${(props) =>
      props.left ? "translateX(100%)" : "translateX(-100%)"};

    .overlay-heading {
      color: black;
      font-size: 17px;
      font-weight: 700;
      padding: 5rem 1rem;
      @media screen and (min-width: 992px) {
        padding: 5rem 1.3rem;
      }
      display: flex;
      align-items: center;
      span {
        margin: 0 6px;
        font-size: 20px;
        text-align: center;
      }
    }
  }

  .black-bar {
    width: 30%;
    height: 7px;
    position: absolute;
    right: 0;
    bottom: 3.5rem;
    background-color: black;
  }

  ${({ left }) =>
    !left &&
    css`
      .overlay {
        left: 0;
        justify-content: start;
      }

      .black-bar {
        left: 0;
      }
    `}

  .screen {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    transition: 0.4s;
    opacity: 0;
  }

  .img-link :hover {
    .screen {
      opacity: 1;
    }
    .overlay {
      transform: translateX(0%);
    }
  }
  img {
    width: 100%;
    min-height: 100%;
    border-radius: 6px;
    object-fit: cover;
  }

  h2 {
    color: #383838;
    font-weight: 700;
    font-size: 27px;
    transition: 0.4s;
    :hover {
      color: var(--color-p-5);
    }
  }
  p {
    color: #454545;
    font-size: 16px;
  }
`;

export default PortfolioCard;
