const job01 = {
  title: "Marketing Specialist",
  id: "marketing-specialist",
  location: "Vancouver, BC",
  type: "Full-Time",
  department: "Marketing",
  description:
    "The Marketing Specialist, in conjunction with other members of the Marketing Department, designs specialized solutions for diverse projects at Synic and its partner ventures. Reporting to the Head of Marketing, the Specialist will participate in collaborative creative meetings to brainstorm and curate projects. They wiill design and implement a wide range of strategy, including but not limited to: social media presence; paid ad campaigns; in-person events; commercial shoots; direct outreach; email marketing and sponsored partnership. The role calls for responsiveness to performance metrics for ongoing growth.",
  resp: [
    "Develop marketing strategies and campaigns for in-house and partner ventures",
    "Content Creation for social media, email and other digital marketing solutions",
    "Co-ordinate with in-house team and outsourced contract players",
    "Prepare and maintain Media Kits, and respond to media enquiries",
    "Client and Company Presentations",
    "Prepare Performance Reports",
  ],
  exp: [
    "1 - 3 years experience in social media management",
    "2 - 5 years experience in content and campaign creation",
    "Or, degree relevant to field combined with experience",
    "Kick-ass copy, creative and technical writing abilities",
    "Instinct for appropriate aesthetic and solution for all kinds of ventures",
    "Exceptional ease marketing via all social and business media channels",
  ],
  attr: [
    "Exceptional communication skills",
    "Creative and open-minded",
    "Ability to establish positive connections with collaborators and partners",
    "Takes initiative and makes suggestions",
    "Positive attitude",
    "Values honest mistakes over poor communication",
  ],
  bonus: [
    "Video editing skills",
    "Experience with Canva or Figma",
    "Background in Software Development or Information Technology",
    "Ongoing Learning Certifications",
  ],
};

const job02 = {
  title: "UX/UI Designer",
  id: "ui-ux-designer",
  location: "Vancouver, BC",
  type: "Full-Time",
  department: "Marketing",
  description:
    "The UX/UI Designer tackles a range of design challenges for diverse projects. They are responsible for shaping customer experience and therefore, the efficacy of the deliverable for the client. They ensure that all digital experiences are seamless, progressive and enjoyable, and suggest innovations. They are ardent researchers, knowledgable about our clients' industries, and prepared to present choices and methodologies to stakeholders.",
  resp: [
    "Steer, test and validate design direction through sprint cycles for diverse projects",
    "Research client industries to make informed choices with user metrics and competitve assessments",
    "Generate compelling ideas for innovation and optimization of digital experience",
    "Prepare and give presentations to colleagues and stakeholders with regard to UX & UI solutions and direction",
    "Define design direction while remaining open to feedback and collaboration",
  ],
  exp: [
    "2 - 4 years proven experience in a similar role, or relevant degree plus experience",
    "Demonstrable experience in user research and metrics",
    "Sound proficiency with Figma and Adobe XD",
    "Excellent communicator",
    "Intuitive and empiric understanding of UX and UI design",
    "Self-responsible and exceptional multi-tasker",
  ],
  attr: [
    "Tech-nerd",
    "Creative and open-minded",
    "Ability to establish positive connections with collaborators and partners",
    "Takes initiative and makes suggestions",
    "Positive attitude",
    "Values honest mistakes over poor communication",
  ],
  bonus: [
    "Agile Certified Practitioner Qualification",
    "Ongoing Learning Certifications",
    "Experience as a developer",
  ],
};

const job03 = {
  title: "Creative and Copy Writer",
  id: "creative-copy-writer",
  location: "Vancouver, BC",
  type: "Part-Time",
  department: "Marketing",
  description:
    "This position at Synic has the opportunity to work on a wide range of projects. The Creative and Copy Writer will work on assignments provided by the Head of Marketing or Marketing Specialist. They will write compelling text for websites; apps; social media; blog posts; article logs; grant applications and other projects as assigned.",
  resp: [
    "Research and write blogs and articles on assigned topics",
    "Generate copy and text that is accessible and engaging",
    "Draft language for grant applications",
    "Write creative copy for social media campaigns, websites and other platforms",
    "Brainstorm marketing solutions with the Marketing Team",
    "Spell-check and level up text, left, right and centre",
  ],
  exp: [
    "1 - 3 years in an equivalent role",
    "Degree or certification in relevant field such as Journalism or Communications",
    "Working knowledge of Google Docs, Trello and Monday.com",
    "Strong attention-to-detail with exceptional spelling and grammar",
    "Organized, precise and efficient",
  ],
  attr: [
    "Kick-ass communicator",
    "A natural way with words and personal writing voice but...",
    "The ability to transform tone and assembly for the specific project",
    "Positive attitude and willing to take initiative",
    "Creative point of view",
  ],
  bonus: [
    "Workable knowledge of Canva or Figma",
    "Experience with paid ad campaigns for digital marketing",
    "Background in software development or information technology",
  ],
};

export const jobs = [job01, job02, job03];

export const getSingleJob = (id) => {
  const singleJob = jobs.filter((j) => {
    return j.id === id;
  });

  return singleJob[0] ? singleJob[0] : null;
};
