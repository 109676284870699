import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--color-p-3)",
    },
    "& .MuiInput-underline:after": {
      color: "var(--color-p-3)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ced4da",
        borderWidth: "1px",
        width: "100%",
        borderRadius: "5px",
      },
      "&:hover fieldset": {
        borderWidth: "1px",
        borderColor: "#ced4da",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        borderColor: "var(--color-p-3)",
      },

      "& .MuiOutlinedInput-input": {
        padding: "15px 14px",
      },
    },
  },
})(TextField);

export default CustomTextField;

export const CustomTextField2 = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--color-p-3)",
    },
    "& .MuiInput-underline:after": {
      color: "var(--color-p-3)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ced4da",
        borderWidth: "1px",
        width: "100%",
        borderRadius: "5px",
      },
      "&:hover fieldset": {
        borderWidth: "1px",
        borderColor: "#ced4da",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        borderColor: "var(--color-p-3)",
      },

      "& .MuiOutlinedInput-input": {
        padding: "15px 14px",
        color: "#fff",
        backgroundColor: "rgba(0,0,0,0.6)",
        borderRadius: "5px",
      },
    },
  },
})(TextField);
