import React from "react";
import styled from "styled-components";
import { Row, Col, Collapse } from "reactstrap";
import { BiDownArrowCircle, BiUpArrowCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
const CarrerListing = ({ job, index, handleClick, status }) => {
  const click = (e) => {
    handleClick(e, index, !status);
  };

  return (
    <CareerListingWrapper>
      <Row onClick={click} className="cursor">
        <Col className="d-flex mt-3 justify-content-between">
          <div className="desc-heading mb-1">
            <h3>{job.title}</h3>
            <p>
              {job.location} | {job.type} | {job.department}
            </p>
          </div>
          <div className="icon-wrapper">
            {status ? <BiUpArrowCircle /> : <BiDownArrowCircle />}
          </div>
        </Col>
      </Row>
      <Row className="mb-2">
        <Collapse isOpen={status} className="collapseMenu">
          <Col>
            <h4>Role Description</h4>
            <Col>
              <p className="mb-1">
                {job.description.slice(0, 320)}...{" "}
                <Link to={`/careers/${job.id}`}>read more »</Link>
              </p>
            </Col>
          </Col>
        </Collapse>
      </Row>
      <Row>
        <Col>
          <div className="border-btm" />
        </Col>
      </Row>
    </CareerListingWrapper>
  );
};

const CareerListingWrapper = styled.div`
  .cursor {
    cursor: pointer;
  }
  .collapseMenu {
    width: 100%;
    h4 {
      font-size: 17px;
      font-weight: 600;
      color: #2d2d2d;
    }
    p,
    li {
      font-size: 16px;
      color: #4d5455;
    }
    ul {
      list-style: outside;
      padding-left: 20px;
    }
    h5 {
      font-size: 14px;
    }
  }
  .desc-heading {
    h3 {
      font-size: 24px;
      color: #0b88ee;
      transition: 0.3s;
      cursor: pointer;
      :hover {
        color: #096cbd;
      }
    }
    p {
      color: #4d5455;
      font-size: 15px;
    }
  }

  .border-btm {
    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    font-size: 35px;
    color: #0b88ee;
    transition: 0.3s;
    cursor: pointer;
    :hover {
      color: #096cbd;
    }
  }
`;

export default CarrerListing;
