import React from "react";
import {
  AiOutlineLinkedin,
  // AiOutlineYoutube,
  AiOutlineFacebook,
  AiOutlineInstagram,
} from "react-icons/ai";
import { FiYoutube } from "react-icons/fi";

export const socialLinks = [
  {
    url: "https://www.linkedin.com/company/scm-inc/",
    name: "Linkedin",
    imgUrl:
      "https://res.cloudinary.com/dp94c6f82/image/upload/v1624854089/synic-new/icons/linkedin.svg",
    icon: <AiOutlineLinkedin />,
    category: "social media",
  },
  {
    url: "https://www.facebook.com/Synic-Creative-Marketing-110241608055743/",
    name: "Facebook",
    imgUrl:
      "https://res.cloudinary.com/dp94c6f82/image/upload/v1624854089/synic-new/icons/facebook.svg",
    icon: <AiOutlineFacebook />,
    category: "social media",
  },
  {
    url: "https://www.instagram.com/syniccm/",
    name: "Instagram",
    imgUrl:
      "https://res.cloudinary.com/dp94c6f82/image/upload/v1624854089/synic-new/icons/instagram.svg",
    icon: <AiOutlineInstagram />,
    category: "social media",
  },
  {
    url: "https://www.youtube.com/channel/UCH6I9f_zloDNc0mOL_Q3T1w",
    name: "Youtube",
    imgUrl:
      "https://res.cloudinary.com/dp94c6f82/image/upload/v1624854089/synic-new/icons/youtube.svg",
    icon: <FiYoutube />,
    category: "social media",
  },
];

export const navbarLinks = [
  {
    name: "Home",
    category: "home",
    url: "/",
  },
  {
    name: "About",
    url: "/about-us",
    category: "about",
    excerpt:
      "We take care of your problems so that they are not your problems anymore and then you can focus on what you do best.",
    viewLink: {
      text: "Get to know us »",
      category: "about",
      url: "/about-us",
    },
    sublinks: [
      {
        name: "About Us",
        category: "about",
        url: "/about-us",
      },
      {
        name: "Careers",
        category: "careers",
        url: "/careers",
      },
      {
        name: "Contact Us",
        category: "contact",
        url: "/contact",
      },
    ],
  },
  {
    name: "Services",
    url: "/services",
    category: "services",
    excerpt:
      "All your whizbangs, whosits and whatsits. Check out what our in-house experts have to offer.",
    viewLink: {
      text: "View all services »",
      category: "services",
      url: "/services",
    },
    sublinks: [
      {
        name: "Social Media Management",
        category: "services",
        url: "services",
      },
      {
        name: "Website and Development",
        category: "services",
        url: "services",
      },
      {
        name: "Content Creation",
        category: "services",
        url: "services",
      },
      {
        name: "Online Marketing",
        category: "services",
        url: "services",
      },
      {
        name: "Grant Writing",
        category: "services",
        url: "services",
      },
      {
        name: "Cyber Security",
        category: "services",
        url: "services",
      },
      {
        name: "Request a Quote",
        category: "quote",
        url: "/quote",
      },
    ],
  },
];

export const sidebarLinks = [
  {
    name: "Home",
    url: "/",
    category: "home",
  },
  {
    name: "About",
    url: "/about-us",
    category: "about",
    sublinks: [
      {
        name: "About Us",
        url: "/about-us",
        category: "about",
      },
      {
        name: "Careers",
        category: "careers",
        url: "/careers",
      },
      {
        name: "Our Team",
        category: "Synic Software",
        url: "https://synicsw.com/about-us",
        target_outside: true,
      },
    ],
  },
  {
    name: "Services",
    category: "services",
    url: "/services",
    sublinks: [
      {
        name: "Social Media Management",
        category: "services",
        url: "services",
      },
      {
        name: "Website and Development",
        category: "services",
        url: "services",
      },
      {
        name: "Content Creation",
        category: "services",
        url: "services",
      },
      {
        name: "Online Marketing",
        category: "services",
        url: "services",
      },
      {
        name: "Grant Writing",
        category: "services",
        url: "services",
      },
      {
        name: "Cyber Security",
        category: "services",
        url: "services",
      },
      {
        name: "Request a Quote",
        category: "quote",
        url: "/quote",
        ref: false,
      },
    ],
  },
  {
    name: "Contact Us",
    category: "contact",
    url: "/contact",
  },
];

export const footerLinks = [
  {
    name: "Company",
    sublinks: [
      {
        name: "About Us",
        category: "about",
        url: "/about-us",
      },
      {
        name: "Careers",
        url: "/careers",
        category: "careers",
      },
    ],
  },
  {
    name: "Resources",
    sublinks: [
      {
        name: "Contact Us",
        category: "contact",
        url: "/contact",
      },
    ],
  },
  // {
  //   name: "Partners",
  //   sublinks: [
  //     {
  //       name: "Synic Software",
  //       category: "Synic Software",
  //       target_outside: true,
  //       url: "https://synicsw.com",
  //     },
  //     // {
  //     //   name: "Synic Creative Marketing",
  //     //   category: "Synic Creative Marketing",
  //     //   target_outside: true,
  //     //   url: "https://syniccreative.marketing",
  //     // },
  //   ],
  // },
];
