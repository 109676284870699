import React, { useEffect, useState, useRef } from "react";
// import useWindowDimensions from "../../Utility/windowDimensions";
const AppearWrapper = ({ children, alwaysAppear }) => {
  const topRef = useRef(null);
  const [appear, setAppear] = useState(false);
  // const { width } = useWindowDimensions();

  const toggleAppear = () => {
    if (topRef && topRef.current) {
      const top = topRef.current.offsetTop;
      const winHeight = window.innerHeight;
      // const offSet = width > 768 ? winHeight / 3 : winHeight / 1.5;
      if (window.scrollY > top - winHeight) {
        setAppear(true);
      } else {
        // setAppear(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleAppear);
    if (alwaysAppear) {
      setAppear(alwaysAppear);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={topRef}
      className={`appear-transition ${
        appear ? "after-animation" : "before-animation"
      }`}
    >
      {children}
    </div>
  );
};

export default AppearWrapper;
