import React from "react";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import styled from "styled-components";
import Col from "reactstrap/lib/Col";
import Button02 from "../Shared/Button02";
import { Link } from "react-router-dom";
import AppearWrapper from "../Shared/AppearWrapper";

const InfoHorizontal = ({
  data: { title, cards, button },
  bg,
  alwaysAppear,
}) => {
  return (
    <InfoHorizontaltWrapper className="py-10" bg={bg}>
      <AppearWrapper alwaysAppear={alwaysAppear}>
        <Container>
          <Row>
            <Col lg="12">
              {title.map((item, index) => {
                return (
                  <h1 key={index} className="text-left mb-1">
                    {item}
                    <br />
                  </h1>
                );
              })}
            </Col>
            <div className="divider mt-2 mb-2"></div>
          </Row>
          <Row>
            {cards.map((card, index) => {
              return (
                <Col lg="4" key={index}>
                  <div className="box-icon">
                    <article className="text-center">
                      <div className="icon p-3 mt-4 mb-4">
                        <img src={card.image} alt={card.name} />
                      </div>
                      <div className="text-left">
                        <h4 className="fz-18 mb-1">{card.name}</h4>
                        <p className="fz-16">{card.text}</p>
                      </div>
                    </article>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="mt-5 d-flex justify-content-center justify-content-lg-end">
            <Link to={button.url}>
              <Button02>{button.text}</Button02>
            </Link>
          </Row>
        </Container>
      </AppearWrapper>
    </InfoHorizontaltWrapper>
  );
};

const InfoHorizontaltWrapper = styled.section`
  overflow-y: hidden;
  background-color: ${(props) => (props.bg ? props.bg : "white")};
  h4 {
    font-weight: 600;
  }
  p {
    color: #3e4243;
  }
`;

export default InfoHorizontal;
