import { landingImages } from "./imageImport";

export const landingData = [
  {
    title: "what is online advertising?",
    body: "<p>Online advertising, also known as online marketing, Internet advertising, digital advertising or web advertising, is a form of marketing and advertising which uses the Internet to deliver promotional marketing messages to consumers.</p>",
    direction: "left",
    image: landingImages[0],
  },
  {
    title: "Types of online advertising",
    body: "<ol><li>Social Media advertising <br/> e.g Facebook, Instagram, and Linkedln</li><li>Paid Search Advertising</li><li>Display Advertising</li></ol>",
    direction: "right",
    image: landingImages[1],
  },
];
