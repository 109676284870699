import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { NavBg } from "../../Components/Layout";
import { Helmet } from "react-helmet";
const ErrorPage = () => {
  return (
    <ErrorPageWrapper className="pt-30 pb-20">
      <Helmet>
        <title>Error404 | Not Found </title>
        <meta
          name="description"
          content="Sorry, Page not found"
          data-react-helmet="true"
        />
      </Helmet>
      <NavBg />
      <div className="container">
        <h1>
          Page Not Found <span>¯\_(ツ)_/¯</span>
        </h1>
        <p className="lead lead-sm">
          We cannot find the page you have requested. Please contact{" "}
          <a href="mailto:support@synicsw.com">support@synicsw.com</a> for
          assistance.
        </p>
        <p className="lead lead-sm">
          We apologise for any inconvenience caused.
        </p>
        <p className="lead lead-sm">
          <Link to="/">Return to the Homepage</Link>
        </p>
      </div>
    </ErrorPageWrapper>
  );
};

const ErrorPageWrapper = styled.section`
  h1 {
    color: black;
    margin-bottom: 1rem;
  }

  span {
    color: var(--color-p-4);
  }
`;

export default ErrorPage;
