import React, { useState } from "react";
import styled, { css } from "styled-components";
import { RiArrowDropUpLine } from "react-icons/ri";

const ScrollButton = () => {
  const [pageScroll, setPageScroll] = useState(false);

  const toggleNav = () => {
    if (window.scrollY > 150) {
      setPageScroll(true);
    } else {
      setPageScroll(false);
    }
  };

  window.addEventListener("scroll", toggleNav);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <ScrollButtonWrapper onClick={handleClick} pageScroll={pageScroll}>
      <RiArrowDropUpLine />
    </ScrollButtonWrapper>
  );
};

const ScrollButtonWrapper = styled.button`
  position: fixed;
  z-index: 99990;
  right: 0px;
  bottom: -60px;
  opacity: 0;
  height: 60px;
  width: 60px;
  outline: 0 none;
  border-radius: 50%;
  /* background-color: var(--color-p-2); */
  background-color: var(--color-p-2);
  border: none;
  display: grid;
  place-content: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
    0 3px 1px -2px rgb(0 0 0 / 20%); */
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px;
  color: var(--color-g-4);
  font-size: 50px;
  outline: none;
  :hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px, rgb(0 0 0 / 30%) 0px 4px 28px;
  }

  ${({ pageScroll }) =>
    pageScroll &&
    css`
      /* right: 30px; */
      @media screen and (min-width: 768px) {
        bottom: 38px;
        opacity: 1;
        right: 100px;
      }
    `}
`;

export default ScrollButton;
