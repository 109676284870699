import React from "react";
import Button02 from "./Button02";
import { Helmet } from "react-helmet";
import { NavBg } from "../Layout";
import { Container, Row, Col } from "reactstrap/lib";
import { Link } from "react-router-dom";
import styled from "styled-components";
const ErrorSection = ({ title, category, btn, link }) => {
  return (
    <ErrorWrapper>
      <NavBg />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Row className="text-center d-flex flex-column justify-content-center error-center">
          <Col>
            <h1>Oops, the {category} you're looking for doesn't exist</h1>
          </Col>
          <Col>
            <Link to={link}>
              <Button02 style={{ width: "163px" }}>{btn}</Button02>
            </Link>
          </Col>
        </Row>
      </Container>
    </ErrorWrapper>
  );
};

const ErrorWrapper = styled.div`
  min-height: calc(100vh - 343px);
  min-width: 100%;
  display: grid;
  place-items: center;

  h1 {
    font-size: 30px;
  }

  .error-center {
    margin-top: --108px;
    h1 {
      margin-bottom: 40px;
    }
  }
`;
export default ErrorSection;
