import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Container, Col } from "reactstrap";
import careersbg from "../../Assets/careers/careersbg.jpg";
import { jobs } from "../../Data/careersData";
import CarrerListing from "../../Components/Careers/CarrerListing";
import { Helmet } from "react-helmet";
const CareersPage = () => {
  const [collapseOpen, setCollapseOpen] = useState([]);

  const handleClick = (e, index, open) => {
    e.preventDefault();
    const tempArray = new Array(jobs.length).fill(false);
    tempArray[index] = open;
    setCollapseOpen(tempArray);
  };

  useEffect(() => {
    const tempArray = new Array(jobs.length).fill(false);
    setCollapseOpen(tempArray);
  }, []);

  return (
    <CareersPageWrapper bg={careersbg}>
      <Helmet>
        <title>Careers</title>
      </Helmet>
      <div className="header pt-18 pb-14 mb-6 mb-md-8 mb-lg-10 mb-xl-12">
        <Container>
          <h1>Careers</h1>
          <p>We're always looking for quality co-conspirators.</p>
          <p>
            Reach out to us with a short message, your resume, and your
            portfolio at{" "}
            <a href="mailto:admin@crdigital.ca">admin@crdigital.ca.</a>
          </p>
        </Container>
      </div>
      <Container>
        <Row className="mb-3">
          <Col>
            <h2>Current Job Openings</h2>
          </Col>
        </Row>
        <section className="mb-4">
          {jobs.map((job, index) => {
            return (
              <CarrerListing
                key={index}
                handleClick={handleClick}
                job={job}
                index={index}
                status={collapseOpen[index]}
              />
            );
          })}
        </section>
        <section className="mb-10">
          <h5>
            Interested in our Kool-Aid, but don't see an opening in your field?
          </h5>
          <h5>We're always looking for quality co-conspirators.</h5>
          <h5>
            Reach out to us with a short message, your resume and portfolio at{" "}
            <a href="mailto:admin@crdigital.ca">admin@crdigital.ca</a>
          </h5>
        </section>
      </Container>
    </CareersPageWrapper>
  );
};

const CareersPageWrapper = styled.div`
  h5 {
    color: #4d5455;
    font-size: 17px;
  }
  .header {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url(${(props) => props.bg}) center/cover no-repeat;

    a {
      color: var(--color-p-2);
    }

    h1 {
      line-height: 1.285;
      font-size: 40px;
      margin-bottom: 20px;
      color: white;
      font-weight: 600;
    }
    p {
      font-size: 17px;
      color: white;
      margin: 0;
    }
    @media screen and (min-width: 996px) {
      h1 {
        font-size: 50px;
      }
      p {
        font-size: 18px;
      }
    }
    @media screen and (min-width: 1200px) {
      h1 {
        font-size: 58px;
        margin-bottom: 30px;
      }
    }
  }

  h2 {
    color: #2d2d2d;
    font-size: 28px;
  }
`;

export default CareersPage;
