import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getSingleJob } from "../../Data/careersData";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBg from "../../Components/Layout/NavBg";
import { Container, Row, Col } from "reactstrap/lib";
import { Link } from "react-router-dom";
import Button02 from "../../Components/Shared/Button02";
import LoadingSpinner from "../../Components/Shared/LoadingSpinner";
import ErrorSection from "../../Components/Shared/ErrorSection";
const SingleCareerPage = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(() => true);
    const singleJob = getSingleJob(id);
    setJob(singleJob);
    setLoading(() => false);
  }, [id, loading]);

  if (loading) {
    return <LoadingSpinner title="Careers | Loading" />;
  }

  if (!job) {
    return (
      <ErrorSection
        title="Careers | Not Found"
        category="job"
        link="/careers"
        btn="Back to careers"
      />
    );
  }
  const {
    title,
    location,
    type,
    department,
    description,
    resp,
    exp,
    attr,
    bonus,
  } = job;
  return (
    <SingleCareerPageWrapper className="pt-15 pb-10">
      <Helmet>
        <title>Careers | {title}</title>
      </Helmet>
      <NavBg />
      <Container>
        <Row>
          <Col>
            <p>
              <Link to="/careers">Careers</Link> / {title}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex mt-1 justify-content-between">
            <div className="desc-heading mb-1">
              <h3>{title}</h3>
              <p>
                {location} | {type} | {department}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="desc">
          <Col>
            <h4>Role Description</h4>
            <Col>
              <p className="mb-1">{description}</p>
            </Col>
            <h4>Responsibilities</h4>
            <Col>
              <ul className="mb-1">
                {resp.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </Col>
            <h4>Experience & Skills</h4>
            <Col>
              <ul className="mb-1">
                {exp.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </Col>
            <h4>Desired Attributes</h4>
            <Col>
              <ul className="mb-1">
                {attr.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </Col>
            <h4>Bonus if you have...</h4>
            <Col>
              <ul className="mb-2">
                {bonus.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </Col>
            <h5 className="mb-2">
              Interested? Send a brief cover letter and resume to{" "}
              <a href="mailto:admin@crdigital.ca">admin@crdigital.ca</a>
            </h5>
          </Col>
          <Col
            xs="12"
            className="d-flex justify-content-center justify-content-md-start mt-2"
          >
            <Link to="/careers">
              <Button02>Back to Careers</Button02>
            </Link>
          </Col>
        </Row>
      </Container>
    </SingleCareerPageWrapper>
  );
};

const SingleCareerPageWrapper = styled.div`
  .desc {
    width: 100%;
    h4 {
      font-size: 17px;
      font-weight: 600;
      color: #2d2d2d;
    }
    p,
    li {
      font-size: 16px;
      color: #4d5455;
    }
    ul {
      list-style: outside;
      padding-left: 20px;
    }
    h5 {
      font-size: 14px;
    }
  }
  .desc-heading {
    h3 {
      font-size: 32px;
      color: #0b88ee;
      transition: 0.3s;
      cursor: pointer;
    }
    p {
      color: #4d5455;
      font-size: 15px;
    }
  }
`;

export default SingleCareerPage;
