import styled, { css } from "styled-components";

export const NavbarWrapper = styled.header`
  background-color: transparent;
  position: fixed;
  transition: 0.25s;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  z-index: 99;

  .intro {
    max-width: 310px;
    h4 {
      font-weight: 700;
      color: rgba(0, 0, 0, 0.9);
    }
    p {
      color: rgba(0, 0, 0, 0.8);
    }
    a {
      :hover {
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }
  .sublinks {
    display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    gap: 8px;
    a,
    button {
      color: #404647;
      border: none;
      background: none;
      text-align: left;
      font-size: 16px;
      line-height: 1.6;
      font-weight: 600;
      transition: 0.1s;
      :hover {
        color: var(--color-blue-2);
      }
    }
  }

  .dropDown {
    min-width: 100%;
    max-width: 100%;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 7px -4px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 1200px) {
    .openDropDown {
      display: none;
    }
  }

  img {
    margin: 10px 0;
    transition: 0.2s;
  }

  nav {
    padding-top: 25px;
    padding-bottom: 25px;
    transition: 0.4s;
    z-index: 12;
  }

  .nav-container {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .logo {
    height: 38px;
    font-weight: 700;
    color: white;
  }

  .logo-invert {
    filter: brightness(0) invert(1);
    font-weight: 700;
    color: black;
  }

  .sidebar-btn button {
    border: none;
    outline: none;
    background: none;
    color: #b3b3b3;
    font-size: 32px;
  }

  @media screen and (min-width: 600px) {
    .sidebar-btn button {
      margin-right: 0;
    }
    .branding {
      margin-left: 0;
    }
  }

  .links-container {
    display: none;
  }

  .links-container {
    ul {
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      margin-right: 16px;
    }
    li {
      padding: 17px 14px;
      font-size: 1.125rem;
      line-height: 1.2;
    }
    a,
    button {
      transition: 0.2s;
      color: #b3b3b3;
      border: none;
      background: none;
      min-height: 100%;
      outline: 0;
    }
    li:hover a,
    li:hover button,
    li:active a,
    li:active button,
    li:focus a,
    li:focus button {
      color: white;
    }
    .active-link {
      color: white;
    }
  }

  .quote-button {
    display: flex;
    align-self: center;
    text-transform: uppercase;
    background-color: var(--color-p-2);
    border: 1px solid var(--color-p-2);
    margin-right: 20px;
    padding: 0 24px;
    a {
      color: var(--color-g-5);
      align-self: center;
    }
    :hover {
      background-color: transparent;
      a {
        color: var(--color-p-2);
      }
    }
  }

  @media screen and (max-width: 520px) {
    .quote-button {
      display: none;
    }
  }
  ${({ navScroll }) =>
    navScroll &&
    css`
      background-color: white;
      box-shadow: 0 2px 7px -4px rgba(0, 0, 0, 0.2);
      .sidebar-btn button {
        color: #454545;
      }

      .links-container {
        li a,
        li button {
          color: #454545;
        }
        li:hover a,
        li:hover button,
        li:active a,
        li:active button,
        li:focus a,
        li:focus button {
          color: #0b88ee;
        }

        .active-link {
          color: #0b88ee;
        }
      }
      .quote-button {
        :hover {
          /* border: 1px solid var(--color-g-5); */
          background-color: transparent;
          a {
            color: var(--color-g-5);
          }
        }
      }
    `}

  @media screen and (min-width: 1200px) {
    .sidebar-btn {
      display: none;
    }

    .links-container {
      display: block;
    }
  }
`;
