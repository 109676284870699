function importAll(path) {
  return path.keys().map(path);
}

export const titleImages = importAll(
  require.context("../Assets/portfolio/title", false, /\.(png|jpe?g|svg)$/)
);

export const teamImages = importAll(
  require.context("../Assets/about/team", false, /\.(png|jpe?g|svg)$/)
);

export const bioImages = importAll(
  require.context("../Assets/about/bio", false, /\.(png|jpe?g|svg)$/)
);

export const portfolioDetailsImages = importAll(
  require.context("../Assets/portfolio/details", false, /\.(png|jpe?g|svg)$/)
);

export const landingImages = importAll(
  require.context("../Assets/landing/info", false, /\.(png|jpe?g|svg)$/)
);
