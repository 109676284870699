import React from "react";
import styled from "styled-components";
import { Container, Col } from "reactstrap";
import Parser from "html-react-parser/dist/html-react-parser";

const Info = ({ data: { title, body, image, direction } }) => {
  return (
    <InfoWrapper background={image}>
      <div
        className={`d-flex ${
          direction === "left" ? "flex-row" : "flex-row-reverse"
        }`}
      >
        <Col xs="12" md="9" lg="8" className="info-container">
          <h1>{title}</h1>
          <div className="body-container">
            <div className="info-body">
              <Container>{Parser("" + body)}</Container>
            </div>
          </div>
        </Col>
        <Col md="3" lg="4" className="side-bg"></Col>
      </div>
    </InfoWrapper>
  );
};

const InfoWrapper = styled.div`
  .info-container {
    padding: 100px 0;
    @media screen and (min-width: 768px) {
      padding: 150px 0;
    }
    @media screen and (min-width: 992px) {
      padding: 200px 0;
    }
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(${(props) => props.background}) center/cover no-repeat;
    display: grid;
    grid-template-rows: auto 1fr;
    justify-content: center;
    h1 {
      color: white;
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      padding: 20px 40px;
      @media screen and (min-width: 768px) {
        font-size: 32px;
      }
      @media screen and (min-width: 992px) {
        font-size: 34px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 38px;
      }
    }

    .body-container {
      padding: 0 30px;
    }

    .info-body {
      color: white;
      max-width: 700px;
      padding: 40px 40px;
      background-color: rgb(112, 112, 112, 0.5);
      margin: 15px 0;
      border-radius: 37px;
      position: relative;

      @media screen and (min-width: 768px) {
        background-color: rgb(112, 112, 112, 1);
      }

      :after {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        border: 6px solid var(--color-p-2);
        border-radius: 37px;
        width: 100%;
        height: 100%;
        font-size: 17px;
        @media screen and (min-width: 768px) {
          top: 12px;
          left: 12px;
        }
        @media screen and (min-width: 992px) {
          top: 14px;
          left: 14px;
          border: 7px solid var(--color-p-2);
        }
      }

      p {
        padding: 0;
        margin: 0;
      }
      ol {
        list-style-position: inside;
        padding-top: 20px;
        li {
          text-align: left;
          padding-bottom: 20px;
          margin-left: 20%;
        }
      }
      @media screen and (min-width: 992px) {
        font-size: 18px;
      }
    }

    @media screen and (min-width: 768px) {
      background: #272727;
    }
  }

  .side-bg {
    padding: 60px 0;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url(${(props) => props.background}) center/cover no-repeat;
  }
`;

export default Info;
