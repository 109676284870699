import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { socialLinks } from "../../Data/linksData";
import { FaEnvelope, FaCheck } from "react-icons/fa";
import { footerLinks } from "../../Data/linksData";
import validator from "validator";

import { useStylingContext } from "../../Contexts/StylingContext";
import Footer2 from "./Footer2";
const Footer = () => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [emailVal, setEmailVal] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setEmailVal("");
    setEmailLoading(true);
    if (!email) {
      setEmailVal("Email Required");
    } else if (!validator.isEmail(email)) {
      setEmailVal("Please provide valid email");
    }

    if (email && validator.isEmail(email)) {
      setEmail("");
      setSent(true);
      setEmailVal("");
    } else {
    }
    setEmailLoading(false);
  };

  return (
    <FooterWrapper className="pt-3 pt-sm-7 pb-2">
      <div className="container no-padding-right">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="row">
              {footerLinks.map((link, index) => {
                return (
                  <div className="col-lg col-6" key={index}>
                    <div className="widget mb-0 mb-md-5">
                      <h2 className="fz-18 font-weight-bold white">
                        {link.name}
                      </h2>

                      <hr />
                      <ul className="links-container">
                        {link.sublinks.map((sublink, index) => {
                          return (
                            <li key={index} className="mb-1">
                              {sublink.target_outside ? (
                                <a
                                  className="fz-16 white"
                                  href={sublink.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {sublink.name}
                                </a>
                              ) : (
                                <Link className="fz-16 white" to={sublink.url}>
                                  {sublink.name}
                                </Link>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-lg-4 order-1 order-lg-2 mb-3 mb-lg-0 extra-padding-left no-padding-right">
            <div className="row">
              <div className="col-lg-12 no-padding-right extra-padding-left">
                <div className="mb-0 mb-md-5">
                  <h2 className="fz-18 font-weight-bold white">
                    C&R Digital Consulting
                  </h2>
                  <div>
                    <div>
                      <a
                        href="https://maps.google.ca/?q=Synic Software"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fz-16 white"
                      >
                        #555-409 Granville St, Vancouver, BC, Canada
                      </a>
                    </div>
                    <div>
                      <a
                        href="mailto:admin@crdigital.ca"
                        className="fz-16 white"
                      >
                        admin@crdigital.ca
                      </a>
                    </div>
                    <div>
                      <a className="fz-16 white" href="tel:+16046811184">
                        (604) 681-1184
                      </a>
                    </div>
                  </div>

                  <div>
                    <form
                      noValidate
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      className="validate"
                    >
                      <div className="row no-gutters mt-2">
                        <div className="col-9 col-md-8">
                          <input
                            type="email"
                            name="Email"
                            id="Email"
                            disabled={sent}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control rounded-right fz-14"
                            placeholder={
                              sent
                                ? "Thanks! We'll keep you updated"
                                : "Let's Connect!"
                            }
                          />
                          <div
                            className={`invalid-email mb-1 ${
                              sent ? "valid-email" : ""
                            }`}
                          >
                            {emailVal}
                          </div>
                        </div>
                        <div className="col-3">
                          <button
                            onClick={handleEmailSubmit}
                            type="submit"
                            className={`form-submit button style-flat rounded-left btn-news ${
                              sent ? "sent" : ""
                            }`}
                            disabled={sent || emailLoading}
                          >
                            {sent ? <FaCheck /> : <FaEnvelope />}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="fz-14 white">
            &copy; {new Date().getFullYear()} Rights Reserved |{" "}
            <Link className="fz-14 white" to="/privacy-policy">
              Privacy Policy
            </Link>{" "}
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: black;
  color: white;

  a {
    transition: 0.15s;
    :hover {
      color: var(--color-p-2) !important;
    }
  }

  hr {
    color: white !important;
    background-color: white;
    max-width: 135px;
    margin: 1rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 1;
  }

  .no-padding-right {
    padding-right: 0;
  }
  .extra-padding-left {
    @media screen and (min-width: 992px) {
      padding-left: 30px;
    }
  }

  h2 {
    font-weight: 700;
  }

  .links-container {
    padding: 0;
  }
  .social-networks {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      margin-right: 15px;
    }
  }

  input {
    height: 48px;
    border: none;
  }

  button {
    outline: none;
    padding: 0 26px;
    background-color: var(--color-blue-2);
    border: 1px solid var(--color-blue-2);
    :hover {
      background: transparent;
      color: var(--color-blue-2);
    }
  }

  .sent {
    padding: 0 26px;
    background-color: #30bf5b;
    border: 1px solid #30bf5b;
    :hover {
      background: #30bf5b;
      color: white;
      cursor: not-allowed;
    }
  }
  .invalid-email {
    padding-top: 5px;
    color: #dc3545;
  }

  valid-email {
    color: #30bf5b;
  }
`;

export default Footer;
