import React from "react";
import {
  IoPhonePortraitOutline,
  IoLocationOutline,
  IoMailOpenOutline,
} from "react-icons/io5";
import { Row, Container } from "reactstrap";
import styled from "styled-components";
import { socialLinks } from "../../Data/linksData";
const ContactInfo = () => {
  return (
    <ContactInfoWrapper>
      <Container>
        <Row className="d-flex flex-column">
          <div className="header mb-3">
            <div className="icon-wrapper">
              <IoLocationOutline />
            </div>
            <h2>Address</h2>
          </div>
          <div className="body pb-3 mb-3">
            <a
              href="https://www.google.ca/maps?q=Synic+Software"
              target="_blank"
              rel="noopener noreferrer"
            >
              #555-409 Granville Street Vancouver, BC, Canada
            </a>
          </div>
        </Row>
        <Row className="d-flex flex-column">
          <div className="header mb-3">
            <div className="icon-wrapper">
              <IoPhonePortraitOutline />
            </div>
            <h2>Phone</h2>
          </div>
          <div className="body pb-3 mb-3">
            <a href="tel:6046811184">(604) 681-1184</a>
          </div>
        </Row>
        <Row className="d-flex flex-column">
          <div className="header mb-3">
            <div className="icon-wrapper extra-margin-left">
              <IoMailOpenOutline />
            </div>
            <h2>Email</h2>
          </div>
          <div className="body pb-3 mb-1">
            <a href="mailto:admin@crdigital.ca">admin@crdigital.ca</a>
          </div>
        </Row>
      </Container>
    </ContactInfoWrapper>
  );
};

const ContactInfoWrapper = styled.div`
  .header {
    display: flex;
    align-items: center;
    .icon-wrapper {
      font-size: 35px;
      display: flex;
      align-items: center;
      color: #282c30;
      margin-left: -5px;
      margin-right: 20px;
    }
    .extra-margin-left {
      margin-left: -3px;
    }

    h2 {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: #343a40;
      padding: 0;
      margin: 0;
    }
  }
  a {
    color: #0b88ee;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 14px;
    :hover {
      color: #096cbd;
    }
  }

  .header {
    a {
      :visited {
        color: #343a40;
      }
    }
  }

  .body {
    border-bottom: 1px solid rgb(238, 238, 238);
  }

  .icon-container {
    a {
      margin-right: 8px;
      font-size: 22px;
      color: var(--color-g-4);
      :hover {
        color: var(--color-g-5);
      }
    }
  }
`;

export default ContactInfo;
