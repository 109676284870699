import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

const Button01 = ({ children, outlined }) => {
  return (
    <ButtonWrapper>
      <Button
        variant={outlined ? "outlined" : "contained"}
        className={`btn ${outlined ? "outlined" : "contained"}`}
      >
        {children}
      </Button>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  margin: 0 16px;

  .btn {
    color: black;
    width: 163px;
    height: 48px;
    font-size: 16px;
    background-color: var(--color-p-2);
  }

  .contained {
    :hover {
      background: transparent;
      border: 1px solid var(--color-p-2);
      color: var(--color-p-2);
    }
  }

  .outlined {
    border: 1px solid white;
    background: transparent;
    color: white;
    :hover {
      color: black;
      background: white;
    }
  }
`;

export default Button01;
