import React, { useEffect, useState } from "react";
import styled from "styled-components";

const NavBg = () => {
  const [navScroll, setNavScroll] = useState(false);

  const toggleNav = () => {
    if (window.scrollY > 2) {
      setNavScroll(true);
    } else {
      setNavScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleNav);
    return () => window.removeEventListener("scroll", toggleNav);
  }, []);

  return (
    <NavBgWrapper>
      <div className={`${navScroll ? "scroll" : ""}`}></div>
    </NavBgWrapper>
  );
};

const NavBgWrapper = styled.div`
  div {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 108px;
    transition: 0.25s;
    background: black;
  }

  .scroll {
    height: 58px;
  }
`;

export default NavBg;
